import { baseOnAccountUrl } from '../../../baseApi';

export const workUrl = (
    tenantName: string,
    contractorAssignmentId: string,
    periodId: string
  ) => `
      ${baseOnAccountUrl}/${tenantName}/contractor-assignments/${contractorAssignmentId}/periods/${periodId}/work
  `;
  export const saveWorkUrl = (
    tenantName: string,
    contractorAssignmentId: string,
    periodId: string,
    taskId: string
  ) => `
      ${baseOnAccountUrl}/${tenantName}/contractor-assignments/${contractorAssignmentId}/periods/${periodId}/tasks/${taskId}/work
  `;
  export const deleteWorkUrl = (
    tenantName: string,
    contractorAssignmentId: string,
    periodId: string,
    taskId: string,
    workId: string
  ) => `
      ${baseOnAccountUrl}/${tenantName}/contractor-assignments/${contractorAssignmentId}/periods/${periodId}/tasks/${taskId}/work/${workId}
  `;