import React, { useContext, useEffect, useRef, useState } from 'react';

import AdminSidebar from '../../components/Admin/AdminSidebar';
import DomainsList from '../../components/Domains/DomainsList';
import DomainsSidePane from '../../components/Domains/DomainsSidePane';
import Subheader from '../../components/Subheader/Subheader';
import { ConfirmationModal } from '../../components/Modal';

import { GlobalContext } from '../../contexts';

import {
  useApi,
  useDomainsDelete,
  useDomainsPost,
  useOutsideEvent,
} from '../../hooks';
import { domainsUrl, tenantsUrl, tenantDomainsUrl } from '../../settings/api';

import { IDomain, IDomainForm } from '../../types';

const initialDomainState = {
  id: '',
  partitionKey: '',
  tenantName: '',
  logoUrl: '',
  fullScreenImageUrl: '',
  darkColor: '',
  lightColor: '',
  name: '',
};

const defaultTenantWrapperValue = 'All';

function SuperAdminDomains() {
  const [domains, setDomains] = useState<IDomain[]>([]);
  const [tenants, setTenants] = useState<string[]>([]);
  const [displayMessage, setDisplayMessage] = useState<string>('');
  const [selectedTenant, setSelectedTenant] = useState<string>(
    defaultTenantWrapperValue
  );
  const [isVisibleSidepane, setSidepane] = useState<boolean>(false);
  const [isDomainCreate, setCreateDomain] = useState<boolean>(true);
  const [showConfirmationModal, setConfirmationModal] = useState<boolean>(
    false
  );
  const [isWrapperListVisible, setWrapperVisibility] = useState<boolean>(false);
  const [currentDomain, setCurrentDomain] = useState<IDomain>(
    initialDomainState
  );
  const { apiGet } = useApi();
  const { domainPost } = useDomainsPost();
  const { deleteDomain } = useDomainsDelete();
  const { setLoading } = useContext(GlobalContext);
  const sidepaneRef: any = useRef();
  const wrapperListRef = useRef(null);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const tenantsResponse = await apiGet(tenantsUrl());
      const filteredTenants = tenantsResponse.filter(
        (tenant: string) => !tenant.includes('autotest')
      );
      setTenants(['All', ...filteredTenants]);

      const domainsResponse = await apiGet(domainsUrl());
      setDomains(domainsResponse);
      if (!domainsResponse) {
        setDisplayMessage('There is no any domain for this tenant.');
      }
      setLoading(false);
    })();
  }, [apiGet, setLoading]);

  const handleAddDomain = async (data: IDomainForm) => {
    try {
      const newDomain = await domainPost(data);
      setDomains([...domains, newDomain]);
      setSidepane(false);
    } catch (error) {}
  };

  const handleUpdateDomain = async (data: IDomainForm) => {
    try {
      const updatedDomain = await domainPost(data);
      const index = domains.indexOf(currentDomain);
      domains.splice(index, 1, updatedDomain);
      setSidepane(false);
    } catch (error) {}
  };

  const handleDeleteDomain = async () => {
    try {
      await deleteDomain({
        domain: currentDomain.id,
        tenantName: currentDomain.tenantName,
      });
      const index = domains.indexOf(currentDomain);
      domains.splice(index, 1);
      if (!domains.length) {
        setDisplayMessage('There is no any domain.');
      }
      setConfirmationModal(false);
    } catch (error) {
      setConfirmationModal(false);
    }
  };

  const buttonAddClick = () => {
    sidepaneRef.current.resetFormValues();
    setCreateDomain(true);
    setSidepane(true);
  };

  const editDomainClick = (domain: IDomain) => {
    sidepaneRef.current.resetFormValues(domain);
    setCurrentDomain(domain);
    setCreateDomain(false);
    setSidepane(true);
  };

  const deleteDomainClick = (domain: IDomain) => {
    setCurrentDomain(domain);
    setConfirmationModal(true);
  };

  const hideSidepane = () => {
    setSidepane(false);
  };

  const cancelConfirmationModal = () => {
    setConfirmationModal(false);
  };

  const handleWrapperClick = () => {
    if (!isWrapperListVisible) setWrapperVisibility(true);
  };

  const handleWrapperListItemClick = async (e: any) => {
    setWrapperVisibility(false);
    setSelectedTenant(e.target.innerText);
    setDomains([]);
    setDisplayMessage('');
    const domainsResponse =
      e.target.innerText === defaultTenantWrapperValue
        ? await apiGet(domainsUrl())
        : await apiGet(tenantDomainsUrl(e.target.innerText));
    if (domainsResponse.length) {
      setDomains(domainsResponse);
    } else {
      setDisplayMessage('There is no any domain for this tenant.');
    }
  };

  const handleWrapperOutside = () => {
    setWrapperVisibility(false);
  };

  useOutsideEvent(wrapperListRef, handleWrapperOutside);

  return (
    <div className="super-admin">
      <AdminSidebar isSuperAdmin={true} />
      <div className="super-admin-content">
        <Subheader
          buttonAction="Add Domain"
          handleOnClick={buttonAddClick}
          title="Domains"
        />
        <div className="select-wrapper" onClick={handleWrapperClick}>
          <span className="select-wrapper--span">
            {selectedTenant || 'All'}
          </span>
          <div
            className="select-wrapper__list"
            style={{ display: isWrapperListVisible ? 'flex' : 'none' }}
            ref={wrapperListRef}
          >
            {tenants.map((tenant: string) => (
              <span key={tenant} onClick={handleWrapperListItemClick}>
                {tenant}
              </span>
            ))}
          </div>
        </div>
        <DomainsList
          domains={domains}
          displayMessage={displayMessage}
          isSuperAdmin={true}
          editDomainClick={editDomainClick}
          deleteDomainClick={deleteDomainClick}
        />
        <DomainsSidePane
          isVisibleSidepane={isVisibleSidepane}
          isDomainCreate={isDomainCreate}
          isSuperAdmin={true}
          hideSidepane={hideSidepane}
          submitHandler={isDomainCreate ? handleAddDomain : handleUpdateDomain}
          currentDomain={currentDomain}
          ref={sidepaneRef}
        />
      </div>
      <ConfirmationModal
        question={'Are you sure you want delete this domain?'}
        show={showConfirmationModal}
        handleConfirm={handleDeleteDomain}
        handleCancel={cancelConfirmationModal}
      />
    </div>
  );
}

export default SuperAdminDomains;
