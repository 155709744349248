import { useCallback, useContext } from 'react';

import { useApi } from '../api';

import { deleteDomainUrl } from '../../settings/api';
import { GlobalContext } from '../../contexts';

export function useDomainsDelete() {
  const { setLoading } = useContext(GlobalContext);

  const { apiDelete } = useApi();

  const deleteDomain = useCallback(
    async ({ domain, tenantName }: { domain: string; tenantName: string }) => {
      try {
        setLoading(true);

        await apiDelete(deleteDomainUrl(tenantName, domain));

        setLoading(false);
      } catch (error) {
        setLoading(false);
        throw error;
      }
    },
    [apiDelete, setLoading]
  );

  return {
    deleteDomain,
  };
}
