import React from 'react';

interface Props {
  children: React.ReactNode;
  show: boolean;
}

const PopupMenu = React.forwardRef((props: Props, ref: any) => {
  const { children, show } = props;

  const popupMenuStyle = {
    visibility: show ? 'visible' : 'hidden',
  } as React.CSSProperties;

  return (
    <div className="popup-menu" style={popupMenuStyle} ref={ref}>
      {children}
    </div>
  );
});

export default PopupMenu;
