import React from 'react';

interface Props {
  title: string;
  handleCancel: () => void;
}

function SidePaneHeader(props: Props) {
  const { title, handleCancel } = props;
  return (
    <div className="sidepane-header">
      <span className="sidepane-header__title">{title}</span>
      <span className="sidepane-header__close" onClick={handleCancel}>
        &times;
      </span>
    </div>
  );
}

export default SidePaneHeader;
