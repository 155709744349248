import React, { useContext, useRef, useState } from 'react';

import AdminNavigation from '../../components/Admin/AdminNavigation';
import AdminSidebar from '../../components/Admin/AdminSidebar';
import DomainsSidePane from '../../components/Domains/DomainsSidePane';
import { ConfirmationModal, PopupModal } from '../../components/Modal';

import { GlobalContext } from '../../contexts';

import {
  useDomainsDelete,
  useDomainsGet,
  useDomainsPost,
  useOutsideEvent,
} from '../../hooks';

import { IDomain, IDomainForm } from '../../types';
import DomainsList from '../../components/Domains/DomainsList';

const initialDomainState = {
  id: '',
  partitionKey: '',
  tenantName: '',
  logoUrl: '',
  fullScreenImageUrl: '',
  darkColor: '',
  lightColor: '',
  name: '',
};

function Domains() {
  const [isVisibleSidepane, setSidepane] = useState(false);
  const [isDomainCreate, setCreateDomain] = useState(true);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [showPopupModal, setPopupModal] = useState(false);
  const [currentDomain, setCurrentDomain] = useState<IDomain>(
    initialDomainState
  );
  const {
    tenant: { name: tenantName },
  } = useContext(GlobalContext);
  const {
    domains,
    setDomains,
    displayMessage,
    setDisplayMessage,
  } = useDomainsGet();
  const { tenantDomainPost, isSuccess, setSuccess } = useDomainsPost();
  const { deleteDomain } = useDomainsDelete();
  const domainsRef = useRef(null);
  const sidepaneRef: any = useRef(null);

  const handleAddDomain = async (data: IDomainForm) => {
    try {
      const newDomain = await tenantDomainPost({ ...data, tenantName });
      setDomains([...domains, newDomain]);
      setSidepane(false);
      setPopupWithTimeout(1000);
    } catch (error) {
      setPopupWithTimeout(1000);
    }
  };

  const handleUpdateDomain = async (data: IDomainForm) => {
    try {
      const updatedDomain = await tenantDomainPost({ ...data, tenantName });

      const index = domains.indexOf(currentDomain);
      domains.splice(index, 1, updatedDomain);
      setSidepane(false);
      setPopupWithTimeout(1000);
    } catch (error) {
      setPopupWithTimeout(1000);
    }
  };

  const handleDeleteDomain = async () => {
    try {
      await deleteDomain({
        domain: currentDomain.id,
        tenantName: currentDomain.tenantName,
      });
      setSuccess(true);
      const index = domains.indexOf(currentDomain);
      domains.splice(index, 1);
      if (!domains.length) {
        setDisplayMessage('There is no any domain for this tenant.');
      }
      setConfirmationModal(false);
      setPopupWithTimeout(1000);
    } catch (error) {
      setSuccess(false);
      setConfirmationModal(false);
      setPopupWithTimeout(1000);
    }
  };

  const handleAddButtonClick = () => {
    sidepaneRef.current.resetFormValues();
    setCreateDomain(true);
    setSidepane(true);
  };

  const editDomainClick = (domain: IDomain) => {
    sidepaneRef.current.resetFormValues(domain);
    setCurrentDomain(domain);
    setCreateDomain(false);
    setSidepane(true);
  };

  const deleteDomainClick = (domain: IDomain) => {
    setCurrentDomain(domain);
    setConfirmationModal(true);
  };

  const hideSidepane = () => {
    setSidepane(false);
  };

  const cancelConfirmationModal = () => {
    setConfirmationModal(false);
  };

  const hidePopupModal = () => {
    setPopupModal(false);
  };

  const setPopupWithTimeout = (time: number) => {
    setPopupModal(true);
    setTimeout(() => {
      setPopupModal(false);
    }, time);
  };

  useOutsideEvent(domainsRef, hideSidepane);

  return (
    <div className="domains">
      <AdminSidebar />
      <div className="domains-content">
        <AdminNavigation
          title="Domains"
          tenantName={tenantName}
          buttonClick={handleAddButtonClick}
          buttonTitle="Add Domain"
        />
        <DomainsList
          domains={domains}
          displayMessage={displayMessage}
          isSuperAdmin={false}
          editDomainClick={editDomainClick}
          deleteDomainClick={deleteDomainClick}
        />
        <DomainsSidePane
          isVisibleSidepane={isVisibleSidepane}
          isDomainCreate={isDomainCreate}
          isSuperAdmin={false}
          hideSidepane={hideSidepane}
          submitHandler={isDomainCreate ? handleAddDomain : handleUpdateDomain}
          currentDomain={currentDomain}
          ref={sidepaneRef}
        />
      </div>
      <PopupModal
        message={isSuccess ? 'Success.' : 'Something went wrong.'}
        success={isSuccess}
        show={showPopupModal}
        onHide={hidePopupModal}
      />
      <ConfirmationModal
        question={'Are you sure you want delete this domain?'}
        show={showConfirmationModal}
        handleConfirm={handleDeleteDomain}
        handleCancel={cancelConfirmationModal}
      />
    </div>
  );
}

export default Domains;
