import { useCallback, useContext, useState } from 'react';

import { GlobalContext } from '../../contexts';

import { useApi } from '../api';

import { domainsUrl, tenantDomainsUrl } from '../../settings/api';
import { IDomainForm } from '../../types';

export function useDomainsPost() {
  const {
    setLoading,
    tenant: { name: tenantName },
  } = useContext(GlobalContext);
  const { apiPost } = useApi();
  const [isSuccess, setSuccess] = useState(false);

  const tenantDomainPost = useCallback(
    async (data: IDomainForm) => {
      try {
        setLoading(true);
        const newDomain = await apiPost(tenantDomainsUrl(tenantName), data);

        setLoading(false);
        setSuccess(true);

        return newDomain;
      } catch (error) {
        setLoading(false);
        setSuccess(false);
      }
    },
    [apiPost, setLoading, tenantName]
  );

  const domainPost = useCallback(
    async (data: IDomainForm) => {
      try {
        setLoading(true);
        const updatedDomain = await apiPost(domainsUrl(), data);

        setLoading(false);
        setSuccess(true);

        return updatedDomain;
      } catch (error) {
        setLoading(false);
        setSuccess(false);
      }
    },
    [apiPost, setLoading]
  );

  return {
    tenantDomainPost,
    domainPost,
    isSuccess,
    setSuccess,
  };
}
