import React, { CSSProperties, useRef } from 'react';

import { useHover } from '../../hooks';

interface Props {
  clickHandler: () => void;
  isMenuOpened: boolean;
  additionalStyle?: CSSProperties;
  handleHover: (isHover: boolean) => void;
}

function TableItemMore(props: Props) {
  const { clickHandler, isMenuOpened, additionalStyle, handleHover } = props;
  const moreRef = useRef(null);

  const moreDotsStyle = {
    pointerEvents: isMenuOpened ? 'none' : 'auto',
  } as CSSProperties;

  useHover(moreRef, handleHover);

  return (
    <div
      className="table-more data-row"
      onClick={clickHandler}
      style={{ ...moreDotsStyle, ...additionalStyle }}
      ref={moreRef}
    >
      <span className="table-more__content" />
    </div>
  );
}

export default TableItemMore;
