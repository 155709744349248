import { useContext } from 'react';

import { GlobalContext } from '../../contexts';

import {
  createHttpMethod,
  setStoredToken,
  removeStoredToken,
  removeStoredTenant,
  removeStoredItems,
} from '../../utils';

import { loginUrl } from '../../settings/api';
import { POST } from '../../constants/http';
import { StorageType, LoginData } from '../../types';

export function useAuth() {
  const {
    user,
    setUser,
    removeUser,
    setLoading,
    tenant: { name: tenantName },
  } = useContext(GlobalContext);

  const login = async ({ email, password, useLocalStorage }: LoginData) => {
    try {
      const storageType: StorageType = useLocalStorage
        ? StorageType.LocalStorage
        : StorageType.SessionStorage;
      setLoading(true);
      const apiPost = createHttpMethod({
        method: POST,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const response = await apiPost(loginUrl(tenantName), {
        email,
        password,
      });
      const { token } = response;

      setUser({ email }, storageType);
      setStoredToken(token, storageType);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };

  const logout = () => {
    removeUser();
    removeStoredToken();
    removeStoredTenant();
    removeStoredItems();
  };

  return { user, setUser, removeUser, login, logout };
}
