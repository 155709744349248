import React from 'react';
import Routes from './routes/Routes';
import GlobalState from './components/Global/GlobalState';
import './styles/style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <GlobalState>
        <Routes />
      </GlobalState>
    </div>
  );
}

export default App;
