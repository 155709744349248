import { useCallback, useContext } from 'react';

import { GlobalContext } from '../../contexts';

import { useApi } from '../api';
import { contractorPeriodSumUrl } from '../../settings/api';
import { getStoredProject } from '../../utils';
import { IPeriodSumPerContractor } from '../../types';
import { periodSumStartDate, periodSumEndDate } from '../../constants/dates';

export function usePeriodSumPerContractor() {
  const {
    setLoading,
    tenant: { name: tenantName },
  } = useContext(GlobalContext);
  const { apiGet } = useApi();
  const { GUID: projectGUID } = getStoredProject();

  const getPeriodSumPerContractor = useCallback(
    async (startDate?: string, endDate?: string) => {
      try {
        setLoading(true);
        const periodSumPerContractor: IPeriodSumPerContractor = await apiGet(
          contractorPeriodSumUrl(
            tenantName,
            projectGUID,
            startDate || periodSumStartDate,
            endDate || periodSumEndDate
          )
        );
        setLoading(false);
        return periodSumPerContractor;
      } catch (error) {
        setLoading(false);
        throw error;
      }
    },
    [apiGet, projectGUID, setLoading, tenantName]
  );

  return {
    getPeriodSumPerContractor,
  };
}
