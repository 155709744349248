import { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useApi } from '../api';

import { taskUrl } from '../../settings/api';
import { GlobalContext } from '../../contexts';

import { ICreateTask } from '../../types';

export function useTasksPost() {
  const [isSuccess, setSuccess] = useState(false);

  const {
    tenant: { name: tenantName },
    setLoading,
  } = useContext(GlobalContext);
  const {
    contractorAssignmentId,
  }: { contractorAssignmentId: string } = useParams();

  const { apiPost } = useApi();

  const addTask = useCallback(
    async (task: ICreateTask) => {
      try {
        setLoading(true);

        const { id } = await apiPost(
          taskUrl(tenantName, contractorAssignmentId),
          task
        );

        setSuccess(true);
        setLoading(false);

        return id;
      } catch (error) {
        setSuccess(false);
        setLoading(false);
        throw error;
      }
    },
    [apiPost, tenantName, contractorAssignmentId, setLoading]
  );

  return {
    addTask,
    isSuccess,
    setSuccess,
  };
}
