import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { useApi } from '../api';

import { deletePeriodUrl } from '../../settings/api';
import { GlobalContext } from '../../contexts';

export function usePeriodsDelete() {
  const {
    tenant: { name: tenantName },
    setLoading,
  } = useContext(GlobalContext);
  const {
    contractorAssignmentId,
  }: { contractorAssignmentId: string } = useParams();

  const { apiDelete } = useApi();

  const deletePeriod = useCallback(
    async (periodId: string) => {
      try {
        setLoading(true);

        await apiDelete(
          deletePeriodUrl(tenantName, contractorAssignmentId, periodId)
        );

        setLoading(false);
      } catch (error) {
        setLoading(false);
        throw error;
      }
    },
    [apiDelete, tenantName, contractorAssignmentId, setLoading]
  );

  return {
    deletePeriod,
  };
}
