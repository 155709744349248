import React from 'react';

import { Modal } from 'react-bootstrap';

import rightTick from '../../assets/right-tick.svg';

interface Props {
  message: string;
}

function SuccessModalBody(props: Props) {
  const { message } = props;
  return (
    <Modal.Body className="success-modal-body">
      <div className="popup-modal-tick">
        <img
          src={rightTick}
          className="popup-modal-tick__image"
          alt="right-tick"
        />
      </div>
      <div className="popup-modal-content">
        <p>HOOORAY!</p>
        <p className="popup-modal-content__message">{message}</p>
      </div>
    </Modal.Body>
  );
}

export default SuccessModalBody;
