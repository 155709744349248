import React, { useContext, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, Spinner } from 'react-bootstrap';

import { useAuth } from '../../hooks';

import { GlobalContext } from '../../contexts';

import { LoginData } from '../../types';
import { history } from '../../utils';

function Login() {
  const [rememberMe, setRememberMe] = useState(true);
  const { login } = useAuth();
  const {
    register,
    handleSubmit,
    errors,
    reset,
    setError,
    setValue,
  } = useForm();
  const { tenant, loading } = useContext(GlobalContext);
  const emailRef = useRef<HTMLInputElement>();
  const passwordRef = useRef<HTMLInputElement>();
  const introMessage = getIntroMessage();

  const onSubmit = async (data: LoginData) => {
    try {
      const { email, password, useLocalStorage } = data;
      await login({
        email,
        password,
        useLocalStorage,
      });
      history.push('/');
    } catch ({ status, message }) {
      if (message.toLowerCase().includes('password')) {
        setValue('password', '');
        setError('password', {
          type: 'manual',
          message,
        });
        passwordRef.current?.focus();
      } else {
        reset();
        setError('email', {
          type: 'manual',
          message,
        });
        emailRef.current?.focus();
      }
    }
  };

  const handleCheckboxClick = () => {
    setRememberMe(!rememberMe);
  };

  if (!tenant.logoUrl) {
    return (
      <div className="login-spinners">
        <Spinner animation="grow" variant="primary" />
        <Spinner animation="grow" variant="primary" />
        <Spinner animation="grow" variant="primary" />
      </div>
    );
  }

  return (
    <div
      className="login"
      style={{
        backgroundImage: `url(${tenant.fullScreenImageUrl})`,
      }}
    >
      <div className="login-form">
        <img className="logo__login" src={tenant.logoUrl} alt="logo" />
        <div className="login-welcome-message">
          <span>{introMessage}</span>
          <br />
          <span>Welcome back.</span>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label htmlFor="email">Email</Form.Label>
            <Form.Control
              autoFocus
              id="email"
              name="email"
              ref={(e: HTMLInputElement) => {
                register(e, { required: true });
                emailRef.current = e;
              }}
              type="email"
            />
            {errors.email && (
              <p className="error-message">{errors.email.message}</p>
            )}
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="password">Password</Form.Label>
            <Form.Control
              id="password"
              name="password"
              ref={(e: HTMLInputElement) => {
                register(e, { required: true });
                passwordRef.current = e;
              }}
              type="password"
            />
            {errors.password && (
              <p className="error-message">{errors.password.message}</p>
            )}
          </Form.Group>

          <Form.Group onClick={handleCheckboxClick}>
            <div className="custom-remember-me">
              <input
                type="checkbox"
                name="useLocalStorage"
                className="custom-remember-me__checkbox"
                checked={rememberMe}
                disabled={loading}
                onChange={handleCheckboxClick}
                ref={register()}
              />
              <span className="custom-remember-me__checkmark" />
              <label className="custom-remember-me__label">Remember me</label>
            </div>
          </Form.Group>

          <Button
            type="submit"
            variant="success"
            block
            disabled={loading}
            size="sm"
          >
            Login
          </Button>
        </Form>

        <p style={{ marginTop: '3.5rem' }}>
          &copy; {new Date().getFullYear()} {tenant.name}
        </p>
      </div>
    </div>
  );
}

function getIntroMessage() {
  const time = new Date().getHours();
  let introMessage = 'Good afternoon!';
  if (time < 12) introMessage = 'Good morning!';
  else if (time >= 18 && time <= 24) introMessage = 'Good evening!';

  return introMessage;
}

export default Login;
