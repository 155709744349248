import React, { Fragment } from 'react';

import ListAllTasks from './ListAllTasks';
import TasksTableRow from './TasksTableRow';

import { IIsCollapsedTaskGroup, ITaskSubGroup } from '../../types';

interface Props {
  taskGroups: ITaskSubGroup[];
  isOpenedTaskGroup: boolean;
  collapsedTaskGroups: IIsCollapsedTaskGroup;
  changeOpenedTaskGroup: (id: string) => void;
}

function ListAllTaskGroups({
  taskGroups,
  isOpenedTaskGroup,
  collapsedTaskGroups,
  changeOpenedTaskGroup,
}: Props) {
  const handleChangeOpenedTaskGroup = (id: string) => {
    changeOpenedTaskGroup(id);
  };

  return taskGroups.length ? (
    <>
      {taskGroups.map((taskGroup) => (
        <Fragment key={taskGroup.id}>
          <TasksTableRow
            id={taskGroup.id}
            displayId={taskGroup.displayId}
            name={taskGroup.name}
            value={taskGroup.value}
            isSubGroup={taskGroup.tasks.length ? true : false}
            setOpenedTaskGroup={handleChangeOpenedTaskGroup}
            isOpenedTaskGroup={collapsedTaskGroups[taskGroup.id]}
            isRowVisible={isOpenedTaskGroup}
          />
          <ListAllTasks
            tasks={taskGroup.tasks}
            isOpenedTaskGroup={
              isOpenedTaskGroup && collapsedTaskGroups[taskGroup.id]
            }
          />
        </Fragment>
      ))}
    </>
  ) : (
    <></>
  );
}

export default ListAllTaskGroups;
