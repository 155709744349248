import { useEffect } from 'react';
import { ESCAPE_CODE } from '../../constants/keyCodes';

export function useOutsideEvent(ref: any, eventHandler: () => void) {
  useEffect(() => {
    function handleOutsideEvent(e: any) {
      if (ref.current && !ref.current.contains(e.target)) {
        if (e.type === 'keydown') {
          if (e.keyCode === ESCAPE_CODE) {
            eventHandler();
          }
        } else {
          eventHandler();
        }
      }
    }

    document.addEventListener('mousedown', handleOutsideEvent);
    document.addEventListener('keydown', handleOutsideEvent);

    return () => {
      document.removeEventListener('mousedown', handleOutsideEvent);
      document.removeEventListener('keydown', handleOutsideEvent);
    };
  }, [eventHandler, ref]);
}
