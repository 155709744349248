import React from 'react';
import { Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

interface Props {
  logoUrl: string;
}

function Logo(props: Props) {
  const { logoUrl } = props;
  const history = useHistory();

  const handleLogoClick = () => {
    history.push('/projects');
  };

  return (
    <Image
      src={logoUrl}
      rounded
      className="logo-component"
      onClick={handleLogoClick}
    />
  );
}

export default Logo;
