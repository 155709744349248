import { useCallback, useContext, useState } from 'react';

import { useApi } from '../api';

import { contractorUrl, contractorAssignmentUrl } from '../../settings/api';
import { GlobalContext } from '../../contexts';

import { IContractor, IContractorAssignment } from '../../types';
import { getStoredProject } from '../../utils';

export function useContractorsPost() {
  const [isSuccess, setSuccess] = useState(false);

  const {
    tenant: { name: tenantName },
    setLoading,
  } = useContext(GlobalContext);

  const { apiPost } = useApi();
  const { GUID: projectGUID } = getStoredProject();

  const addContractor = useCallback(
    async (contractor: IContractor, trade: string) => {
      try {
        setLoading(true);

        const { id } = await apiPost(contractorUrl(tenantName), contractor);

        const { id: contractorAssignmentId } = await apiPost(
          contractorAssignmentUrl(tenantName, projectGUID),
          {
            trade,
            contractorId: id,
          }
        );
        setSuccess(true);
        setLoading(false);

        contractor.id = id;

        return {
          newContractor: contractor,
          newContractorAssignment: {
            tenantName,
            trade,
            id: contractorAssignmentId,
            partitionKey: tenantName,
            projectId: projectGUID,
            contractorId: id,
          },
        };
      } catch (error) {
        setSuccess(false);
        setLoading(false);
        throw error;
      }
    },
    [apiPost, projectGUID, setLoading, tenantName]
  );

  const updateContractor = useCallback(
    async (contractor: IContractor) => {
      try {
        setLoading(true);

        await apiPost(contractorUrl(tenantName), contractor);

        setSuccess(true);
        setLoading(false);

        return contractor;
      } catch (error) {
        setSuccess(false);
        setLoading(false);
        throw error;
      }
    },
    [apiPost, setLoading, tenantName]
  );

  const updateContractorAssignment = useCallback(
    async (contractorAssignment: IContractorAssignment) => {
      try {
        setLoading(true);

        await apiPost(
          contractorAssignmentUrl(tenantName, projectGUID),
          contractorAssignment
        );

        setSuccess(true);
        setLoading(false);

        return contractorAssignment;
      } catch (error) {
        setSuccess(false);
        setLoading(false);
        throw error;
      }
    },
    [apiPost, setLoading, tenantName, projectGUID]
  );

  return {
    addContractor,
    updateContractor,
    isSuccess,
    setSuccess,
    updateContractorAssignment,
  };
}
