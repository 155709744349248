import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { getStoredToken } from '../../utils';

function UnauthorizedRoute(props: RouteProps) {
  const token = getStoredToken();

  if (token) {
    return <Redirect to={'/'} />;
  }

  return <Route {...props} />;
}

export default UnauthorizedRoute;
