import {IPeriod } from '../types';

import moment from 'moment';

export function findLastMonthId(startDate: string, periods: IPeriod[]) {
    const lastMonthDate = moment(startDate)
      .subtract(1, 'month')
      .format('YYYY-MM-DDTHH:mm:ss');
  
    const lastMonth = periods.find(
      (period) => period.startDate === lastMonthDate
    );
  
    return lastMonth?.id;
  }