import React from 'react';

import TasksTableRow from './TasksTableRow';

import { ITask } from '../../types';

interface Props {
  tasks: ITask[];
  isOpenedTaskGroup: boolean;
}

function ListAllTasks({ tasks, isOpenedTaskGroup }: Props) {
  return tasks.length ? (
    <>
      {tasks.map((task) => (
        <TasksTableRow
          key={task.id}
          id={task.id}
          displayId={task.displayId}
          name={task.name}
          value={task.value}
          isRowVisible={isOpenedTaskGroup}
        />
      ))}
    </>
  ) : (
    <></>
  );
}

export default ListAllTasks;
