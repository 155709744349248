import { useContext, useEffect, useState } from 'react';

import { useApi } from '../api';

import { projectContractorsUrl } from '../../settings/api';

import { GlobalContext } from '../../contexts';

import { IContractor } from '../../types';
import { getStoredProject } from '../../utils';

export function useContractorsGet() {
  const [contractors, setContractors] = useState<IContractor[]>([]);
  const [displayMessage, setDisplayMessage] = useState('');
  const {
    tenant: { name: tenantName },
    setLoading,
  } = useContext(GlobalContext);

  const { apiGet } = useApi();
  const { GUID: projectGUID } = getStoredProject();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response: IContractor[] = await apiGet(
        projectContractorsUrl(tenantName, projectGUID)
      );
      setContractors(response);
      if (!response.length) {
        setDisplayMessage('There are not yet any contractors on this case.');
      }
      setLoading(false);
    })();
  }, [apiGet, tenantName, projectGUID, setLoading]);

  return {
    contractors,
    setContractors,
    displayMessage,
    setDisplayMessage,
  };
}
