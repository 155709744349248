import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useApi } from '../api';

import { periodUrl } from '../../settings/api';

import { GlobalContext } from '../../contexts';

import { IPeriod } from '../../types';

import moment from 'moment';

export function usePeriodsGet() {
  const [periods, setPeriods] = useState<IPeriod[]>([]);
  const [displayMessage, setDisplayMessage] = useState('');
  const [suggestedPeriodValue, setSuggesterPeriodValue] = useState('');
  const {
    tenant: { name: tenantName },
    setLoading,
  } = useContext(GlobalContext);

  const { apiGet } = useApi();
  const {
    contractorAssignmentId,
  }: { contractorAssignmentId: string } = useParams();
  const periodSuggestedDate = moment().add(1, 'month').format('YYYY-MM');

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response: IPeriod[] = await apiGet(
        periodUrl(tenantName, contractorAssignmentId)
      );
      if (!response.length) {
        setSuggesterPeriodValue(periodSuggestedDate);
        setDisplayMessage('There is no any month yet.');
      } else {
        setSuggesterPeriodValue(
          moment(response[0].startDate).add(1, 'month').format('YYYY-MM')
        );
        setPeriods(response);
      }
      setLoading(false);
    })();
  }, [
    apiGet,
    tenantName,
    contractorAssignmentId,
    setLoading,
    periodSuggestedDate,
  ]);

  return {
    periods,
    setPeriods,
    displayMessage,
    setDisplayMessage,
    suggestedPeriodValue,
    setSuggesterPeriodValue,
    periodSuggestedDate,
    contractorAssignmentId,
  };
}
