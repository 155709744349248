import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';

import { SidePane, SidePaneHeader, SidePaneFooter } from '../SidePane';

import { useOutsideEvent } from '../../hooks';

import { IDomain, IDomainForm } from '../../types';

interface Props {
  isVisibleSidepane: boolean;
  isDomainCreate: boolean;
  isSuperAdmin: boolean;
  hideSidepane: () => void;
  submitHandler: (data: IDomainForm) => Promise<void>;
  currentDomain: IDomain;
}

const DomainsSidePane = forwardRef(
  (
    {
      isVisibleSidepane,
      isDomainCreate,
      isSuperAdmin,
      hideSidepane,
      submitHandler,
      currentDomain,
    }: Props,
    ref: any
  ) => {
    const { register, handleSubmit, reset, errors } = useForm();
    const sidepaneRef = useRef();

    const tenantNameStyle = {
      display: isSuperAdmin ? 'block' : 'none',
    };

    useImperativeHandle(ref, () => ({
      resetFormValues(domain?: IDomain) {
        reset({
          domain: domain?.id || '',
          tenantName: domain?.tenantName || '',
          fullScreenImageUrl: domain?.fullScreenImageUrl || '',
          logoUrl: domain?.logoUrl || '',
        });
      },
    }));

    useOutsideEvent(sidepaneRef, hideSidepane);

    return (
      <SidePane show={isVisibleSidepane} ref={sidepaneRef}>
        <Form onSubmit={handleSubmit(submitHandler)}>
          <SidePaneHeader title="Add Domain" handleCancel={hideSidepane} />
          <Form.Label>Domain ID</Form.Label>
          <Form.Control
            type="text"
            name="domain"
            disabled={isDomainCreate ? false : true}
            ref={register({
              required: true,
            })}
            isInvalid={errors.domain ? true : false}
          />
          <Form.Label style={tenantNameStyle}>Tenant Name</Form.Label>
          <Form.Control
            type="text"
            name="tenantName"
            disabled={isSuperAdmin ? false : true}
            ref={register({
              required: isSuperAdmin,
            })}
            isInvalid={errors.tenantName ? true : false}
            style={tenantNameStyle}
          />
          <Form.Label>Full screen image URL</Form.Label>
          <Form.Control
            type="text"
            name="fullScreenImageUrl"
            ref={register({
              required: true,
            })}
            isInvalid={errors.fullScreenImageUrl ? true : false}
          />
          <Form.Label>Logo URL</Form.Label>
          <Form.Control
            type="text"
            name="logoUrl"
            ref={register({
              required: true,
            })}
            isInvalid={errors.logoUrl ? true : false}
          />
          <Form.Label>Dark color</Form.Label>
          <Form.Control
            type="color"
            name="darkColor"
            defaultValue={isDomainCreate ? '#808080' : currentDomain.darkColor}
            ref={register()}
          />
          <Form.Label>Light color</Form.Label>
          <Form.Control
            type="color"
            name="lightColor"
            defaultValue={isDomainCreate ? '#ffffff' : currentDomain.lightColor}
            ref={register()}
          />
          <SidePaneFooter
            handleCancel={hideSidepane}
            confirmationButtonTitle={isDomainCreate ? 'Create' : 'Update'}
          />
        </Form>
      </SidePane>
    );
  }
);

export default DomainsSidePane;
