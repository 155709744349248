interface Config extends RequestInit {
  preventParse?: boolean;
}

function createHttpMethod(config: Config) {
  return async (url: string, body?: any) => {
    config = {
      ...config,
      body: JSON.stringify(body),
    };

    const response = await fetch(url, config);
    const { ok, status } = response;
    if (ok) {
      return await response.json();
    } else {
      let message = null;
      switch (status) {
        case 404: {
          message = 'Not found';

          break;
        }
        case 500: {
          message = 'Something went wrong.';
          break;
        }
        default: {
          const { message: errorMessage } = await response.json();

          message = errorMessage;
          break;
        }
      }
      const error = {
        status,
        message,
      };
      return Promise.reject(error);
    }
  };
}

export default createHttpMethod;
