import React, { useContext, useState, useRef, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';

import AdminNavigation from '../../components/Admin/AdminNavigation';
import AdminSidebar from '../../components/Admin/AdminSidebar';
import UserListItem from '../../components/Users/UserListItem';
import { PopupModal } from '../../components/Modal';
import {
  SidePane,
  SidePaneHeader,
  SidePaneFooter,
} from '../../components/SidePane';

import { GlobalContext } from '../../contexts';

import {
  useOutsideEvent,
  useTenantUsersGet,
  useTenantUsersPost,
} from '../../hooks';

import { ITenantUserForm } from '../../types';
import SkeletonTable from '../../components/Skeleton/SkeletonTable';

function Users() {
  const [showSidepane, setSidepane] = useState(false);
  const [isPasswordVisible, setPasswordVisibility] = useState(false);
  const [showPopupModal, setPopupModal] = useState(false);
  const { register, handleSubmit, reset, errors } = useForm();
  const {
    tenant: { name: tenantName },
  } = useContext(GlobalContext);
  const { users, setUsers, displayMessage } = useTenantUsersGet();
  const { addUser, isSuccess } = useTenantUsersPost();
  const sidePaneRef = useRef(null);

  const handleAddUser = async (data: ITenantUserForm) => {
    try {
      const newUser = await addUser(data);
      setUsers([...users, newUser]);
      setSidepane(false);
      setPopupWithTimeout(1000);
    } catch (error) {
      setSidepane(false);
      setPopupWithTimeout(1000);
    }
  };

  const handleAddButtonClick = () => {
    setSidepane(true);
  };

  const hidePopupModal = () => {
    setPopupModal(false);
  };

  const handleSidePaneCancel = () => {
    reset();
    setSidepane(false);
  };

  const setPopupWithTimeout = (time: number) => {
    setPopupModal(true);
    setTimeout(() => {
      reset();
      setPopupModal(false);
    }, time);
  };

  const changePasswordVisibility = () => {
    setPasswordVisibility(!isPasswordVisible);
  };

  useOutsideEvent(sidePaneRef, handleSidePaneCancel);

  return (
    <div className="users">
      <AdminSidebar />
      <div className="users-content">
        <AdminNavigation
          title="Users"
          buttonClick={handleAddButtonClick}
          buttonTitle="Add user"
          tenantName={tenantName}
        />
        {!displayMessage && !users.length ? (
          <SkeletonTable />
        ) : users.length ? (
          <div className="users-content__list">
            <div />
            <div className="users-content__list__email header">Email</div>
            <div className="users-content__list__name header">Name</div>
            <div className="users-content__list__phone header">Phone</div>
            <div />
            <div />
            {users.map((user) => (
              <Fragment key={user.id}>
                <UserListItem user={user} />
              </Fragment>
            ))}
          </div>
        ) : (
          <div className="contractors-display-message">
            <span>{displayMessage}</span>
          </div>
        )}

        <SidePane show={showSidepane} ref={sidePaneRef}>
          <SidePaneHeader
            title="Add user"
            handleCancel={handleSidePaneCancel}
          />
          <Form onSubmit={handleSubmit(handleAddUser)}>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              name="email"
              ref={register({
                required: true,
              })}
              isInvalid={errors.email ? true : false}
            />
            <Form.Label>Password</Form.Label>
            <Form.Control
              type={isPasswordVisible ? 'text' : 'password'}
              name="password"
              ref={register({
                required: true,
                minLength: 6,
              })}
              isInvalid={errors.password ? true : false}
            />
            <i
              className="material-icons login-form__password-input"
              onClick={changePasswordVisibility}
            >
              remove_red_eye
            </i>
            {errors.password && (
              <p className="error-message">
                {errors.password.type === 'minLength'
                  ? 'Password must be at least 6 characters length'
                  : ''}
              </p>
            )}
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" name="name" ref={register()} />
            <Form.Label>Phone Number</Form.Label>
            <Form.Control type="text" name="phoneNumber" ref={register()} />
            <SidePaneFooter handleCancel={handleSidePaneCancel} />
          </Form>
          <PopupModal
            message={isSuccess ? 'Success.' : 'Something went wrong.'}
            success={isSuccess}
            show={showPopupModal}
            onHide={hidePopupModal}
          />
        </SidePane>
      </div>
    </div>
  );
}

export default Users;
