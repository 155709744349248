import { baseOnAccountUrl } from '../../../baseApi';

export const taskUrl = (tenantName: string, contractorAssignmentId: string) =>
  `${baseOnAccountUrl}/${tenantName}/contractor-assignments/${contractorAssignmentId}/tasks`;
export const deleteTaskUrl = (
  tenantName: string,
  contractorAssignmentId: string,
  taskId: string
) => `
    ${baseOnAccountUrl}/${tenantName}/contractor-assignments/${contractorAssignmentId}/tasks/${taskId}
`;

export const taskGroupUrl = (
  tenantName: string,
  contractorAssignmentId: string
) =>
  `${baseOnAccountUrl}/${tenantName}/contractor-assignments/${contractorAssignmentId}/task-groups`;
export const deleteTaskGroupUrl = (
  tenantName: string,
  contractorAssignmentId: string,
  taskGroupId: string
) => `
    ${baseOnAccountUrl}/${tenantName}/contractor-assignments/${contractorAssignmentId}/task-groups/${taskGroupId}
`;

export const taskCompletionUrl = (
    tenantName: string,
    contractorAssignmentId: string
  ) => `
      ${baseOnAccountUrl}/${tenantName}/contractor-assignments/${contractorAssignmentId}/tasks/completed-percentage
  `;
