import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  tenantName: string;
  buttonClick: () => void;
  buttonTitle: string;
}

function AdminNavigation({
  title,
  tenantName,
  buttonClick,
  buttonTitle,
}: Props) {
  return (
    <>
      <nav className="admin-subheader__navigation">
        <p>
          <Link to="/admin">Admin</Link>
        </p>
        <p> {` / `}</p>
        <p>{title}</p>
      </nav>
      <div className="admin-subheader__title">
        <h2>Tenant - {tenantName}</h2>
      </div>
      <div className="admin-subheader__footer">
        <h4>{title}</h4>
        <Button variant="success" onClick={buttonClick}>
          {buttonTitle}
        </Button>
      </div>
    </>
  );
}

export default AdminNavigation;
