import React, { useContext } from 'react';

import { GlobalContext } from '../../contexts';

import Logo from '../Logo/Logo';
import AdminListItem from './AdminListItem';
import { UserProfileItem } from '../Items';

interface Props {
  isSuperAdmin?: boolean;
}

function AdminSidebar({ isSuperAdmin = false }: Props) {
  const { tenant } = useContext(GlobalContext);
  return (
    <div className="admin-sidebar">
      <div className="admin-sidebar__items">
        <span
          className={`admin-sidebar__super-admin${
            isSuperAdmin ? '--visible' : '--hidden'
          }`}
        >
          SuperAdmin
        </span>
        <AdminListItem
          title="Domains"
          iconName="domains"
          isSuperAdmin={isSuperAdmin}
          css={{ order: isSuperAdmin ? 2 : 1 }}
        />
        <AdminListItem
          title={isSuperAdmin ? 'Tenants' : 'Users'}
          iconName={isSuperAdmin ? 'home' : 'people'}
          isSuperAdmin={isSuperAdmin}
          css={{ order: isSuperAdmin ? 1 : 2 }}
        />
      </div>
      <div className="admin-sidebar__logo">
        <Logo logoUrl={tenant.logoUrl} />
      </div>
      <UserProfileItem />
    </div>
  );
}

export default AdminSidebar;
