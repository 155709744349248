import React from 'react';
import { Modal } from 'react-bootstrap';

import crossMark from '../../assets/cross-mark.svg';

interface Props {
  message: string;
  onCrossMarkClick: () => void;
}

function ErrorModalBody(props: Props) {
  const { message, onCrossMarkClick } = props;
  return (
    <Modal.Body className="error-modal-body">
      <div className="popup-modal-tick">
        <img
          src={crossMark}
          className="popup-modal__image"
          alt="cross-mark"
          onClick={onCrossMarkClick}
        />
      </div>
      <div className="popup-modal-content">
        <p>WHOOOPS!</p>
        <p className="popup-modal-content__message">{message}</p>
      </div>
    </Modal.Body>
  );
}

export default ErrorModalBody;
