import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, RouteComponentProps } from 'react-router-dom';

import Sidebar from '../../components/Sidebar/Sidebar';
import CalculationsTableRow from '../../components/OnAccount/CalculationsTableRow';
import CalculationsSubheader from '../../components/OnAccount/CalculationsSubheader';
import SkeletonTable from '../../components/Skeleton/SkeletonTable';

import { useApi, usePeriodsPost } from '../../hooks';

import { GlobalContext } from '../../contexts';
import { ICompletedPercentages, ITask, IWork, ICustomWork } from '../../types';
import {
  workUrl,
  saveWorkUrl,
  taskUrl,
  taskCompletionUrl,
} from '../../settings/api';

import {
  formatNumber,
  getStoredContractor,
  getStoredPeriod,
  getStoredProject,
} from '../../utils';

interface Props extends RouteComponentProps {}

function Calculations(props: Props) {
  const [works, setWorks] = useState<IWork[]>([]);
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [lastMonthWorks, setLastMonthWorks] = useState<IWork[]>([]);
  const [
    completedPercentages,
    setCompletedPercentages,
  ] = useState<ICompletedPercentages>({});
  const [displayMessage, setDisplayMessage] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const {
    tenant: { name: tenantName },
    setLoading,
  } = useContext(GlobalContext);
  const contractor = getStoredContractor();
  const period = getStoredPeriod();
  const { GUID: projectId } = getStoredProject();
  const {
    contractorAssignmentId,
  }: { contractorAssignmentId: string } = useParams();
  const { apiGet, apiPost } = useApi();
  const { createOrUpdatePeriod } = usePeriodsPost();
  const { lastMonthId } = props.location.state as { lastMonthId: string };

  const calculateTotalAmount = useCallback((works: IWork[], tasks: ITask[]) => {
    let sum = 0;
    works.forEach((work) => {
      tasks.forEach((task) => {
        if (task.id === work.taskId) {
          sum += (task.value * work.percentageOfTask) / 100;
        }
      });
    });
    setTotalAmount(sum);
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const worksResponse: IWork[] = await apiGet(
        workUrl(tenantName, contractorAssignmentId, period.id)
      );
      setWorks(worksResponse);

      const completedPercentagesResponse: any = await apiGet(
        taskCompletionUrl(tenantName, contractorAssignmentId)
      );
      setCompletedPercentages(completedPercentagesResponse);

      if (lastMonthId) {
        const lastMonthWorksResponse: IWork[] = await apiGet(
          workUrl(tenantName, contractorAssignmentId, lastMonthId)
        );
        setLastMonthWorks(lastMonthWorksResponse);
      }

      const tasksResponse: ITask[] = await apiGet(
        taskUrl(tenantName, contractorAssignmentId)
      );
      setTasks(tasksResponse);

      setLoading(false);
      if (!tasksResponse.length) {
        return setDisplayMessage('Add task for this contractor to add work.');
      }
      calculateTotalAmount(worksResponse, tasksResponse);
    })();
  }, [
    apiGet,
    tenantName,
    contractorAssignmentId,
    period.id,
    calculateTotalAmount,
    lastMonthId,
    setLoading,
  ]);

  const handleWorkCreate = async (taskId: string, percentageOfTask: number) => {
    try {
      setLoading(true);
      const { id } = await apiPost(
        saveWorkUrl(tenantName, contractorAssignmentId, period.id, taskId),
        {
          projectId,
          taskId,
          contractorId: contractor.id,
          percentageOfTask: percentageOfTask || 0,
        }
      );
      works.push({
        tenantName,
        projectId,
        id,
        taskId,
        contractorAssignmentId,
        partitionKey: `${tenantName}-${contractor.id}`,
        percentageOfTask: percentageOfTask || 0,
        contractorId: contractor.id,
        periodId: period.id,
      });
      const completedPercentagesResponse: any = await apiGet(
        taskCompletionUrl(tenantName, contractorAssignmentId)
      );
      setCompletedPercentages(completedPercentagesResponse);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleWorkUpdate = async (work: IWork) => {
    try {
      setLoading(true);
      await apiPost(
        saveWorkUrl(tenantName, contractorAssignmentId, period.id, work.taskId),
        work
      );
      const completedPercentagesResponse: any = await apiGet(
        taskCompletionUrl(tenantName, contractorAssignmentId)
      );
      setCompletedPercentages(completedPercentagesResponse);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const customWorks = [] as ICustomWork[];
  if (tasks.length) {
    if (!works.length) {
      tasks.forEach((task) => {
        customWorks.push({
          task,
        });
      });
    } else {
      tasks.forEach((task) => {
        works.forEach((work) => {
          if (work.taskId === task.id) {
            customWorks.push({
              task,
              work,
            });
          }
        });
      });
      if (tasks.length > works.length) {
        tasks.forEach((task) => {
          const isTaskAdded = customWorks.find(
            (customWork) => customWork.task.id === task.id
          );
          if (!isTaskAdded) customWorks.push({ task });
        });
      }
    }
    if (lastMonthWorks.length) {
      customWorks.forEach((customWork) => {
        lastMonthWorks.forEach((lastMonthWork) => {
          if (customWork.task.id === lastMonthWork.taskId) {
            customWork.lastMonthWork = lastMonthWork;
          }
        });
      });
    }
  }

  const updateTotalAmount = async (newValue: number, oldValue: number) => {
    const newTotalAmount = totalAmount - oldValue + newValue;
    setTotalAmount(isNaN(newTotalAmount) ? totalAmount : newTotalAmount);
    await createOrUpdatePeriod({
      projectId,
      sum: newTotalAmount,
      id: period.id,
      startDate: period.startDate,
      contractorId: contractor.id,
      duration: 'Month',
    });
  };

  return (
    <div className="calculations">
      <Sidebar />
      <div className="calculations-content">
        <CalculationsSubheader />
        {!displayMessage && !customWorks.length ? (
          <SkeletonTable />
        ) : customWorks.length ? (
          <>
            <div className="calculations-table">
              <div className="calculations-table__header__task header">
                Task
              </div>

              <div className="calculations-table__completed-this-period header right-alignment">
                Completed this Month
              </div>
              <div className="calculations-table__completed-total header right-alignment">
                Completed Total
              </div>
              <div className="calculations-table__last-month-amount header right-alignment">
                Last Month Amount
              </div>
              <div className="calculations-table__current-month-amount header right-alignment">
                Current Month Amount
              </div>
              <div className="calculations-table__total-contract-amount header right-alignment">
                Total Contract Amount
              </div>
              {customWorks.map((customWork) => (
                <CalculationsTableRow
                  key={customWork.task.id}
                  customWork={customWork}
                  completedPercentages={completedPercentages}
                  createNewWork={handleWorkCreate}
                  updateWork={handleWorkUpdate}
                  updateTotalValue={updateTotalAmount}
                />
              ))}

              <div className="table-shadow-line one"></div>
              <div className="table-shadow-line two"></div>
              <div className="table-shadow-line three"></div>
              <div className="table-shadow-line four"></div>
              <div className="table-shadow-line five"></div>
              <div className="table-shadow-line six"></div>
              <div className="calculations-table__footer__title grid-display right-alignment">
                Current Month Total
              </div>
              <div className="calculations-table__footer__value grid-display right-alignment">
                {formatNumber(totalAmount)}
              </div>

              <div className="calculations-table__footer__title flexbox-display right-alignment">
                Current Month Total
              </div>
              <div className="calculations-table__footer__value flexbox-display">
                {formatNumber(totalAmount)}
              </div>
            </div>
          </>
        ) : (
          <div className="periods-display-message">
            <span>{displayMessage}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Calculations;
