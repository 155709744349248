import React from 'react';

function SkeletonCards() {
  const skeletonCards = [];

  for (let i = 0; i < 3; ++i) {
    skeletonCards.push(<div className="skeleton-card" key={i} />);
  }
  return <div className="skeleton-cards">{skeletonCards}</div>;
}

export default SkeletonCards;
