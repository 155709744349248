import React, { forwardRef, useContext } from 'react';

import { GlobalContext } from '../../contexts';

interface Props {
  isHovered: boolean;
  iconName: string;
}

const BorderIcon = forwardRef(({ isHovered, iconName }: Props, ref: any) => {
  const {
    tenant: { darkColor },
  } = useContext(GlobalContext);
  const borderStyle = isHovered
    ? {
        borderLeft: `7px solid ${darkColor}`,
      }
    : {};
  const iconStyle = {
    color: isHovered ? darkColor : '#94abc8',
  };
  return (
    <div className="table-border-icon" style={borderStyle} ref={ref}>
      <i className="material-icons" style={iconStyle}>
        {iconName}
      </i>
    </div>
  );
});

export default BorderIcon;
