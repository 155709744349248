import React, { useRef, useState } from 'react';

import BorderIcon from '../TableCommon/BorderIcon';

import { ITenantUser } from '../../types';
import { useHover } from '../../hooks';
import { TableItemMore } from '../Items';
import TableContextMenu from '../Menu/TableContextMenu';

interface Props {
  user: ITenantUser;
}

function UserListItem({ user: { email, name, phoneNumber } }: Props) {
  const [hasHover, setHover] = useState(false);
  const borderIconRef = useRef(null);
  const emailRef = useRef(null);
  const nameRef = useRef(null);
  const phoneRef = useRef(null);
  const menuRef = useRef(null);

  const handleElementHover = (hasHover: boolean) => {
    setHover(hasHover);
  };

  const handleClickMore = () => {};

  const menuEditClick = () => {};
  const menuDeleteClick = () => {};

  useHover(borderIconRef, handleElementHover);
  useHover(emailRef, handleElementHover);
  useHover(nameRef, handleElementHover);
  useHover(phoneRef, handleElementHover);
  useHover(menuRef, handleElementHover);

  return (
    <>
      <BorderIcon isHovered={hasHover} iconName="person" ref={borderIconRef} />
      <div className="users-content__list__email data-row" ref={emailRef}>
        {email}
      </div>
      <div className="users-content__list__name data-row" ref={nameRef}>
        {name || 'Unknown'}
      </div>
      <div className="users-content__list__phone data-row" ref={phoneRef}>
        {phoneNumber || 'Unknown'}
      </div>
      <TableItemMore
        clickHandler={handleClickMore}
        isMenuOpened={false}
        handleHover={handleElementHover}
      />
      <TableContextMenu
        show={false}
        ref={menuRef}
        handleEditClick={menuEditClick}
        handleDeleteClick={menuDeleteClick}
        handleHover={handleElementHover}
      />
    </>
  );
}

export default UserListItem;
