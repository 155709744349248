import { useCallback, useContext } from 'react';

import { useApi } from '../api';

import { deleteContractorAssignmentUrl } from '../../settings/api';
import { GlobalContext } from '../../contexts';

import { getStoredProject } from '../../utils';

export function useContractorsDelete() {
  const {
    tenant: { name: tenantName },
    setLoading,
  } = useContext(GlobalContext);

  const { apiDelete } = useApi();
  const { GUID: projectGUID } = getStoredProject();

  const deleteContractor = useCallback(
    async (contractorAssignmentId: string) => {
      try {
        setLoading(true);

        await apiDelete(
          deleteContractorAssignmentUrl(
            tenantName,
            projectGUID,
            contractorAssignmentId
          )
        );

        setLoading(false);
      } catch (error) {
        setLoading(false);
        throw error;
      }
    },
    [apiDelete, projectGUID, setLoading, tenantName]
  );

  return {
    deleteContractor,
  };
}
