import React, { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { GlobalContext } from '../../contexts';

interface Props {
  question: string;
  show: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
}

function ConfirmationModal(props: Props) {
  const { question, show, handleConfirm, handleCancel } = props;
  const { loading } = useContext(GlobalContext);
  return (
    <Modal
      show={show}
      onHide={!loading ? handleCancel : () => {}}
      centered
      animation={false}
    >
      <Modal.Body>
        <h4>{question}</h4>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={handleCancel}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button variant="danger" onClick={handleConfirm} disabled={loading}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;
