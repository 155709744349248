import { baseProjectUrl } from '../baseApi';

export const projectUrl = (tenantName: string) => `${baseProjectUrl}/${tenantName}/projects`;
export const deleteProjectUrl = (tenantName: string, projectId: string) => `${baseProjectUrl}/${tenantName}/projects/${projectId}`;
export const projectContractorsUrl = (tenantName: string, projectId: string) =>
  `${baseProjectUrl}/${tenantName}/projects/${projectId}/contractors`;
export const contractorAssignmentUrl = (
    tenantName: string,
    projectId: string
  ) =>
    `${baseProjectUrl}/${tenantName}/projects/${projectId}/contractor-assignments`;
export const deleteContractorAssignmentUrl = (
    tenantName: string,
    projectId: string,
    contractorAssignmentId: string
    ) =>
    `${baseProjectUrl}/${tenantName}/projects/${projectId}/contractor-assignments/${contractorAssignmentId}`;