import React, { CSSProperties } from 'react';
import { useHover } from '../../hooks';

interface Props {
  show: boolean;
  handleEditClick: () => void;
  handleDeleteClick: () => void;
  handleHover: (isHover: boolean) => void;
}

const TableContextMenu = React.forwardRef((props: Props, ref: any) => {
  const { show, handleEditClick, handleDeleteClick, handleHover } = props;

  const menuStyle = {
    visibility: show ? 'visible' : 'hidden',
  } as CSSProperties;

  useHover(ref, handleHover);

  return (
    <ul className="table-context-menu" style={menuStyle} ref={ref}>
      <li className="table-context-menu__item" onClick={handleEditClick}>
        Edit
      </li>
      <li className="table-context-menu__item" onClick={handleDeleteClick}>
        Delete
      </li>
    </ul>
  );
});

export default TableContextMenu;
