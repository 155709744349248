import React, { CSSProperties } from 'react';

import { formatNumber } from '../../utils';

interface Props {
  id: string;
  displayId: string;
  name: string;
  value: number;
  isMainTaskGroup?: boolean;
  isSubGroup?: boolean;
  setOpenedTaskGroup?: (id: string) => void;
  isOpenedTaskGroup?: boolean;
  isRowVisible: boolean;
}

function TasksTableRow({
  id,
  displayId,
  name,
  value,
  isMainTaskGroup,
  isSubGroup,
  setOpenedTaskGroup,
  isOpenedTaskGroup,
  isRowVisible,
}: Props) {
  const isArrowVisible = isMainTaskGroup || isSubGroup;
  const rowClassName = isMainTaskGroup
    ? ' task-group'
    : isSubGroup
    ? ' task-sub-group'
    : ' task';

  const handleArrowClick = () => {
    if (setOpenedTaskGroup) setOpenedTaskGroup(id);
  };

  const rowStyle = {
    pointerEvents: isRowVisible ? 'auto' : 'none',
  } as CSSProperties;

  const additionalClassName = isRowVisible ? '--visible' : '--hidden';

  return (
    <>
      <div
        className={`tasks-table__id data-row${additionalClassName} ${rowClassName}`}
      >
        <i className="material-icons">insert_chart</i>
        <span> {displayId}</span>
      </div>
      <div
        className={`tasks-table__name data-row${additionalClassName} ${rowClassName}`}
      >
        {name}
      </div>
      <div
        className={`tasks-table__value right-alignment data-row${additionalClassName} ${rowClassName}`}
      >
        {formatNumber(value)}
      </div>
      {isArrowVisible ? (
        <div
          className={`tasks-table__arrow data-row${additionalClassName} ${rowClassName} ${
            isOpenedTaskGroup ? 'active' : ''
          }`}
          onClick={handleArrowClick}
          style={rowStyle}
        >
          {' '}
          <i className="material-icons">{'arrow_drop_down'}</i>
        </div>
      ) : (
        <div
          className={`tasks-table__arrow data-row${additionalClassName} ${rowClassName}`}
        ></div>
      )}
    </>
  );
}

export default TasksTableRow;
