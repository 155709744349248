import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import AdminSidebar from '../../components/Admin/AdminSidebar';
import { PopupModal } from '../../components/Modal';
import {
  SidePane,
  SidePaneFooter,
  SidePaneHeader,
} from '../../components/SidePane';
import SkeletonTable from '../../components/Skeleton/SkeletonTable';
import Subheader from '../../components/Subheader/Subheader';
import TenantListItem from '../../components/SuperAdmin/TenantListItem';

import { useOutsideEvent, useTenantGet, useTenantPost } from '../../hooks';

function SuperAdminTenants() {
  const [tenants, setTenants] = useState<string[]>([]);
  const [isVisibleSidepane, setSidepane] = useState<boolean>(false);
  const [showPopupModal, setPopupModal] = useState<boolean>(false);
  const { register, handleSubmit, reset, errors } = useForm();
  const { getTenants } = useTenantGet();
  const { createTenant, isSuccess } = useTenantPost();
  const sidepaneRef = useRef(null);

  useEffect(() => {
    (async () => {
      setTenants(await getTenants());
    })();
  }, [getTenants]);

  const addTenant = async ({ tenantName }: { tenantName: string }) => {
    try {
      await createTenant(tenantName);
      setTenants([...tenants, tenantName]);
      setSidepane(false);
      setPopupWithTimeout(1000);
    } catch (error) {
      setSidepane(false);
      setPopupWithTimeout(1000);
    }
  };

  const filteredTenants = tenants.filter(
    (tenant) => !tenant.includes('autotest')
  );

  const buttonAddClick = () => {
    reset();
    setSidepane(true);
  };

  const hideSidepane = () => {
    setSidepane(false);
  };

  const hidePopupModal = () => {
    setPopupModal(false);
  };

  const setPopupWithTimeout = (time: number) => {
    setPopupModal(true);
    setTimeout(() => {
      setPopupModal(false);
    }, time);
  };

  useOutsideEvent(sidepaneRef, hideSidepane);

  return (
    <div className="super-admin">
      <AdminSidebar isSuperAdmin={true} />
      <div className="super-admin-content">
        <Subheader
          buttonAction="Add Tenant"
          handleOnClick={buttonAddClick}
          title="Tenants"
        />
        {tenants.length ? (
          <div className="super-admin-content__list">
            <div />
            <div className="super-admin-content__list__name header">Name</div>
            {filteredTenants.map((tenant: string) => (
              <Fragment key={tenant}>
                <TenantListItem name={tenant} />
              </Fragment>
            ))}
          </div>
        ) : (
          <SkeletonTable />
        )}
        <SidePane show={isVisibleSidepane} ref={sidepaneRef}>
          <SidePaneHeader title="Add Tenant" handleCancel={hideSidepane} />
          <Form onSubmit={handleSubmit(addTenant)}>
            <Form.Label>Tenant Name</Form.Label>
            <Form.Control
              type="text"
              name="tenantName"
              ref={register({
                required: true,
              })}
              isInvalid={errors.tenantName ? true : false}
            />
            <SidePaneFooter handleCancel={hideSidepane} />
          </Form>
        </SidePane>
      </div>
      <PopupModal
        message={isSuccess ? 'Success.' : 'Something went wrong.'}
        success={isSuccess}
        show={showPopupModal}
        onHide={hidePopupModal}
      />
    </div>
  );
}

export default SuperAdminTenants;
