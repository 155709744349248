import { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useApi } from '../api';

import { periodUrl } from '../../settings/api';
import { GlobalContext } from '../../contexts';

import { IPeriod, IPeriodRequestObject } from '../../types';

export function usePeriodsPost() {
  const [isSuccess, setSuccess] = useState(false);

  const {
    tenant: { name: tenantName },
    setLoading,
  } = useContext(GlobalContext);

  const { apiPost } = useApi();
  const {
    contractorAssignmentId,
  }: { contractorAssignmentId: string } = useParams();

  const createOrUpdatePeriod = useCallback(
    async (period: IPeriodRequestObject): Promise<{ newPeriod: IPeriod }> => {
      try {
        setLoading(true);

        const { id } = await apiPost(
          periodUrl(tenantName, contractorAssignmentId),
          period
        );
        setSuccess(true);
        setLoading(false);

        return {
          newPeriod: {
            ...period,
            id,
            tenantName,
            contractorAssignmentId,
            partitionKey: tenantName,
          },
        };
      } catch (error) {
        setSuccess(false);
        setLoading(false);
        throw error;
      }
    },
    [apiPost, tenantName, contractorAssignmentId, setLoading]
  );

  return {
    createOrUpdatePeriod,
    isSuccess,
    setSuccess,
  };
}
