import React from 'react';
import { Link } from 'react-router-dom';
import { getStoredProject } from '../../utils';

interface Props {
  contractorId?: string;
  projectId: string;
}

function SubheaderNavigation(props: Props) {
  const { contractorId, projectId } = props;
  const { GUID } = getStoredProject();
  return (
    <nav className="subheader-navigation">
      <p>
        <Link to="/projects">Cases </Link>
      </p>
      {contractorId ? (
        <p>
          {` / `}
          <Link to={`/projects/${GUID}`}>{projectId}</Link>
          {` / ${contractorId}`}
        </p>
      ) : (
        <p>
          {` / `} {projectId}
        </p>
      )}
    </nav>
  );
}

export default SubheaderNavigation;
