import React from 'react';

import { Modal } from 'react-bootstrap';

import SuccessModalBody from './SuccessModalBody';
import ErrorModalBody from './ErrorModalBody';

interface Props {
  message: string;
  success?: boolean;
  show: boolean;
  onHide: () => void;
}

function PopupModal(props: Props) {
  const { message, success, show, onHide } = props;

  return (
    <Modal
      className="popup-modal"
      show={show}
      onHide={onHide}
      centered
      animation={false}
    >
      {success ? (
        <SuccessModalBody message={message} />
      ) : (
        <ErrorModalBody message={message} onCrossMarkClick={onHide} />
      )}
    </Modal>
  );
}

export default PopupModal;
