import React from 'react';

interface Props {
  title: string;
  name: string;
  email: string;
  contactPhone: string;
}

function CalculationsInfoBlock(props: Props) {
  const { title, name, email, contactPhone } = props;
  return (
    <div className="calculations-info-block">
      <span style={{ fontWeight: 700 }}>{title}: </span>
      <span className="calculations-info-block__row-display">
        {name}, {email}, {contactPhone}
      </span>
      <div className="calculations-info-block__column-display">
        <span>{name}</span>
        <span>{email}</span>
        <span>{contactPhone}</span>
      </div>
    </div>
  );
}

export default CalculationsInfoBlock;
