import { useCallback, useContext, useState } from 'react';

import { useApi } from '../api';

import { GlobalContext } from '../../contexts';

import { tenantUsersUrl } from '../../settings/api';
import { ITenantUserForm } from '../../types';

export function useTenantUsersPost() {
  const [isSuccess, setSuccess] = useState(false);

  const {
    tenant: { name: tenantName },
    setLoading,
  } = useContext(GlobalContext);

  const { apiPost } = useApi();

  const addUser = useCallback(
    async (user: ITenantUserForm) => {
      try {
        setLoading(true);

        const { id } = await apiPost(tenantUsersUrl(tenantName), user);

        setSuccess(true);
        setLoading(false);

        return {
          id,
          tenantName,
          partitionKey: tenantName,
          ...user,
        };
      } catch (error) {
        setSuccess(false);
        setLoading(false);
        throw error;
      }
    },
    [apiPost, setLoading, tenantName]
  );

  return {
    addUser,
    isSuccess,
    setSuccess,
  };
}
