import { useContext, useEffect, useState } from 'react';

import { useApi } from '../api';

import { tenantDomainsUrl } from '../../settings/api';

import { GlobalContext } from '../../contexts';

import { IDomain } from '../../types';

export function useDomainsGet() {
  const [domains, setDomains] = useState<IDomain[]>([]);
  const [displayMessage, setDisplayMessage] = useState('');
  const {
    tenant: { name: tenantName },
    setLoading,
  } = useContext(GlobalContext);

  const { apiGet } = useApi();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response: IDomain[] = await apiGet(tenantDomainsUrl(tenantName));
      setDomains(response);
      if (!response.length) {
        setDisplayMessage('There is no any domain for this tenant.');
      }
      setLoading(false);
    })();
  }, [apiGet, tenantName, setLoading]);

  return {
    domains,
    setDomains,
    displayMessage,
    setDisplayMessage,
  };
}
