import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../../hooks';

function Logout() {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  });

  return <Redirect to="login" />;
}

export default Logout;
