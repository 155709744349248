import { useState, useEffect, useCallback, useContext } from 'react';

import { GlobalContext, initialTenantState } from '../../contexts';

import { useApi } from '../';

import { getDomainUrl, tenantsUrl } from '../../settings/api';

import { GET } from '../../constants/http';
import { ITenant } from '../../types';
import {
  createHttpMethod,
  setStoredTenant,
  getStoredTenant,
} from '../../utils';

export function useTenantGet() {
  const { setLoading } = useContext(GlobalContext);
  const { apiGet } = useApi();
  const storedTenant = getStoredTenant();
  const [tenant, setTenant] = useState<ITenant>({
    ...initialTenantState,
    ...storedTenant,
  });

  useEffect(() => {
    const getTenant = async () => {
      try {
        if (storedTenant.name) return;
        const domain =
          process.env.NODE_ENV === 'development'
            ? 'test-domain.builddojo.com'
            : window.location.hostname;
        const apiGet = createHttpMethod({
          method: GET,
          headers: {
            Accept: 'application/json',
          },
        });
        const response = await apiGet(getDomainUrl(domain));
        const {
          tenantName: name,
          logoUrl,
          fullScreenImageUrl,
          darkColor,
          lightColor,
        } = response;

        const tenant = {
          name,
          logoUrl,
          fullScreenImageUrl,
          darkColor: darkColor ? darkColor : '#808080',
          lightColor: lightColor ? lightColor : '#ffffff',
        };
        setStoredTenant(tenant);
        setTenant(tenant);
      } catch (error) {
        console.log(error);
      }
    };

    getTenant();
  }, [storedTenant.name]);

  const getTenants = useCallback(async () => {
    setLoading(true);
    const response = await apiGet(tenantsUrl());
    setLoading(false);
    return response;
  }, [apiGet, setLoading]);

  return { tenant, getTenants };
}
