import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';

import AuthRoute from '../components/Routes/AuthRoute';
import ProtectedRoute from '../components/Routes/ProtectedRoute';

import { history } from '../utils';

import {
  Login,
  Logout,
  Projects,
  Contractors,
  Periods,
  Calculations,
  Tasks,
  Admin,
  Domains,
  Users,
  SuperAdmin,
  SuperAdminDomains,
} from '../views';

function Routes() {
  return (
    <Router history={history}>
      <Switch>
        <AuthRoute path="/login" component={Login} />

        <ProtectedRoute path="/logout" component={Logout} />

        <ProtectedRoute exact path="/projects" component={Projects} />
        <ProtectedRoute
          path="/projects/:projectId"
          exact
          component={Contractors}
        />
        <ProtectedRoute
          path="/projects/:projectId/contractor-assignments/:contractorAssignmentId"
          exact
          component={Periods}
        />

        <ProtectedRoute
          path="/projects/:projectId/contractor-assignments/:contractorAssignmentId/periods/:periodId"
          component={Calculations}
        />
        <ProtectedRoute
          path="/projects/:projectId/contractor-assignments/:contractorAssignmentId/tasks"
          component={Tasks}
        />

        <ProtectedRoute path="/admin" exact component={Admin} />
        <ProtectedRoute path="/admin/domains" exact component={Domains} />
        <ProtectedRoute path="/admin/users" exact component={Users} />

        <ProtectedRoute path="/superadmin" exact component={SuperAdmin} />
        <ProtectedRoute
          path="/superadmin/domains"
          exact
          component={SuperAdminDomains}
        />

        <Route exact={true} path="/">
          <Redirect to="/projects" />
        </Route>
        <Route exact path="/superadmin/tenants">
          <Redirect to="/superadmin" />
        </Route>
      </Switch>
    </Router>
  );
}

export default Routes;
