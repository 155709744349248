import React, { useRef, useState } from 'react';

import { TableItemMore } from '../Items';
import TableContextMenu from '../Menu/TableContextMenu';
import BorderIcon from '../TableCommon/BorderIcon';

import { useHover, useOutsideEvent } from '../../hooks';

import { IDomain } from '../../types';

interface Props {
  domain: IDomain;
  openedContextMenu: string;
  classNamePrefix: string;
  changeOpenedContextMenu: (id: string) => void;
  handleEditClick: (domain: IDomain) => void;
  handleDeleteClick: (domain: IDomain) => void;
}

function DomainListItem({
  domain,
  openedContextMenu,
  classNamePrefix,
  changeOpenedContextMenu,
  handleEditClick,
  handleDeleteClick,
}: Props) {
  const {
    id,
    tenantName,
    logoUrl,
    fullScreenImageUrl,
    darkColor,
    lightColor,
  } = domain;
  const menuRef = useRef(null);
  const idRef = useRef(null);
  const tenantNameRef = useRef(null);
  const logoRef = useRef(null);
  const screenImageRef = useRef(null);
  const darkColorRef = useRef(null);
  const lightColorRef = useRef(null);
  const borderIconRef = useRef(null);
  const [hasHover, setHover] = useState(false);

  const showMenu = openedContextMenu === id;

  const handleElementHover = (hasHover: boolean) => {
    setHover(hasHover);
  };

  const handleClickMore = () => {
    changeOpenedContextMenu(id);
  };

  const menuEditClick = () => {
    handleEditClick(domain);
  };

  const menuDeleteClick = () => {
    handleDeleteClick(domain);
  };

  const handleMenuOutsideClick = () => {
    if (showMenu) changeOpenedContextMenu('');
  };

  useOutsideEvent(menuRef, handleMenuOutsideClick);
  useHover(idRef, handleElementHover);
  useHover(tenantNameRef, handleElementHover);
  useHover(logoRef, handleElementHover);
  useHover(screenImageRef, handleElementHover);
  useHover(darkColorRef, handleElementHover);
  useHover(lightColorRef, handleElementHover);
  useHover(menuRef, handleElementHover);
  useHover(borderIconRef, handleElementHover);

  return (
    <>
      <BorderIcon isHovered={hasHover} iconName="domain" ref={borderIconRef} />
      <div
        className={`${classNamePrefix}domains-content__list__logo data-row`}
        ref={logoRef}
      >
        <img src={logoUrl} alt="logoUrl" />
      </div>
      <div
        className={`${classNamePrefix}domains-content__list__id data-row`}
        ref={idRef}
      >
        <span>{id}</span>
      </div>
      <div
        className={`${classNamePrefix}domains-content__list__tenantName data-row`}
        ref={tenantNameRef}
      >
        <span>{tenantName}</span>
      </div>
      <div
        className={`${classNamePrefix}domains-content__list__screen-image data-row`}
        ref={screenImageRef}
      >
        <img src={fullScreenImageUrl} alt="screenImageUrl" />
      </div>
      <div
        className={`${classNamePrefix}domains-content__list__dark-color data-row`}
        ref={darkColorRef}
      >
        <div style={{ backgroundColor: darkColor }} />
      </div>
      <div
        className={`${classNamePrefix}domains-content__list__light-color data-row`}
        ref={lightColorRef}
      >
        <div style={{ backgroundColor: lightColor }} />
      </div>
      <TableItemMore
        clickHandler={handleClickMore}
        isMenuOpened={showMenu}
        handleHover={handleElementHover}
      />
      <TableContextMenu
        show={showMenu}
        ref={menuRef}
        handleEditClick={menuEditClick}
        handleDeleteClick={menuDeleteClick}
        handleHover={handleElementHover}
      />
    </>
  );
}

export default DomainListItem;
