import React from 'react';
import SkeletonLine from './SkeletonLine';

function SkeletonTable() {
  const skeletonTableRows = [];

  for (let i = 0; i < 3; ++i) {
    skeletonTableRows.push(
      <div className="skeleton-table-row" key={i}>
        <SkeletonLine />
        <SkeletonLine />
      </div>
    );
  }

  return (
    <div className="skeleton-table">
      <div className="skeleton-table-header">
        <SkeletonLine />
        <SkeletonLine />
      </div>
      {skeletonTableRows}
    </div>
  );
}

export default SkeletonTable;
