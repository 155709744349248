import React from 'react';
import { Modal, ProgressBar, Button } from 'react-bootstrap';

interface Props {
  isProgressModalVisible: boolean;
  percentageOfImport: number;
  onHide: () => void;
  handleCancelButtonClick: () => void;
}

function ModalWithProgressBar({
  isProgressModalVisible,
  percentageOfImport,
  onHide,
  handleCancelButtonClick,
}: Props) {
  return (
    <Modal
      show={isProgressModalVisible}
      centered
      animation={false}
      onHide={onHide}
    >
      <div className="progress-modal">
        <ProgressBar
          animated
          now={percentageOfImport}
          label={`${percentageOfImport.toFixed(2)}%`}
        />
        <Button variant="outline-secondary" onClick={handleCancelButtonClick}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
}

export default ModalWithProgressBar;
