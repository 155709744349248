import { useEffect } from 'react';

export function useHover(ref: any, eventHandler: (hasHover: boolean) => void) {
  useEffect(() => {
    const currentNode = ref.current;
    function handleMouseEvent(e: any) {
      if (e.type === 'mouseenter') {
        eventHandler(true);
      } else {
        eventHandler(false);
      }
    }
    currentNode.addEventListener('mouseenter', handleMouseEvent);
    currentNode.addEventListener('mouseleave', handleMouseEvent);

    return () => {
      currentNode.removeEventListener('mouseenter', handleMouseEvent);
      currentNode.removeEventListener('mouseleave', handleMouseEvent);
    };
  }, [eventHandler, ref]);
}
