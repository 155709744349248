import { useCallback } from 'react';

import { GET, POST, PUT, DELETE } from '../../constants/http';

import {
  getStoredToken,
  removeStoredToken,
  createHttpMethod,
  removeStoredUser,
} from '../../utils';

export function useApi() {
  const token = getStoredToken();

  const apiGet = useCallback(
    async (url: string) => {
      try {
        const call = createHttpMethod({
          method: GET,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'x-builddojo-token': token ? token : '',
          },
        });

        return await call(url);
      } catch (error) {
        if (error.status === 401) {
          removeStoredUser();
          removeStoredToken();
        }

        throw error;
      }
    },
    [token]
  );

  const apiPost = useCallback(
    async (url: string, body?: any) => {
      try {
        const call = createHttpMethod({
          method: POST,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'x-builddojo-token': token ? token : '',
          },
        });

        return await call(url, body);
      } catch (error) {
        if (error.status === 401) {
          removeStoredUser();
          removeStoredToken();
        }

        throw error;
      }
    },
    [token]
  );

  const apiPut = useCallback(
    async (url: string, body: any) => {
      try {
        const call = createHttpMethod({
          method: PUT,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'x-builddojo-token': token ? token : '',
          },
        });

        return await call(url, body);
      } catch (error) {
        if (error.status === 401) {
          removeStoredUser();
          removeStoredToken();
        }
        throw error;
      }
    },
    [token]
  );

  const apiDelete = useCallback(
    async (url: string, body?: any) => {
      try {
        const call = createHttpMethod({
          method: DELETE,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'x-builddojo-token': token ? token : '',
          },
        });

        return await call(url);
      } catch (error) {
        if (error.status === 401) {
          removeStoredUser();
          removeStoredToken();
        }

        throw error;
      }
    },
    [token]
  );

  return {
    apiGet,
    apiPost,
    apiPut,
    apiDelete,
  };
}
