import React, { useState, useCallback, useEffect } from 'react';

import {
  GlobalContext,
  initialUserState,
  initialTenantState,
} from '../../contexts';

import { useTenantGet } from '../../hooks';

import {
  getStoredUser,
  setStoredUser,
  removeStoredUser,
  getStoredTenant,
} from '../../utils';
import { StorageType, IUser } from '../../types';

interface Props {
  children: React.ReactNode;
}

function Global(props: Props) {
  const { children } = props;
  const storedUser = getStoredUser();
  const [user, setUserState] = useState(storedUser);
  const [loading, setLoading] = useState(false);
  const { tenant: tenantResponse } = useTenantGet();
  const storedTenant = getStoredTenant();
  const [tenant, setTenant] = useState({
    ...initialTenantState,
    ...storedTenant,
  });

  useEffect(() => {
    (async () => {
      try {
        setTenant(tenantResponse);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [tenantResponse]);

  const setUser = useCallback((value: IUser, storage: StorageType) => {
    setUserState(value);
    setStoredUser(value, storage);
  }, []);

  const removeUser = useCallback(() => {
    setUserState(initialUserState);
    removeStoredUser();
  }, []);

  const global = {
    user,
    setUser,
    removeUser,
    tenant,
    setTenant,
    loading,
    setLoading,
  };

  return (
    <GlobalContext.Provider value={global}>{children}</GlobalContext.Provider>
  );
}

export default Global;
