import React from 'react';
import { Link, useParams } from 'react-router-dom';

import CalculationsInfoBlock from './CalculationsInfoBlock';

import {
  getStoredContractor,
  getStoredPeriod,
  getStoredProject,
} from '../../utils';

import moment from 'moment';

function CalculationsSubheader() {
  const { GUID: projectGUID, projectId } = getStoredProject();
  const contractor = getStoredContractor();
  const period = getStoredPeriod();
  const {
    contractorAssignmentId,
  }: { contractorAssignmentId: string } = useParams();

  return (
    <div className="calculations-subheader">
      <nav className="calculations-subheader__navigation">
        <p>
          <Link to="/projects">Cases </Link>
        </p>
        <p>
          {' / '}
          <Link to={`/projects/${projectGUID}`}> {projectId}</Link>
        </p>
        <p>
          {' / '}{' '}
          <Link
            to={`/projects/${projectGUID}/contractor-assignments/${contractorAssignmentId}`}
          >
            {contractor.displayId}
          </Link>
        </p>
        <p>
          {' / '} {moment(period.startDate).format('MMMM YYYY')}
        </p>
      </nav>
      <div className="calculations-subheader__body">
        <div className="calculations-subheader__body__title">
          <h2>Calculation - {moment(period.startDate).format('MMMM YYYY')}</h2>
        </div>
      </div>
      <div className="calculations-subheader__footer">
        <div className="calculations-subheader__footer__informations">
          <i className="material-icons">info</i>
          <div className="calculations-subheader__footer__informations__info-blocks">
            <CalculationsInfoBlock
              title="Contractor"
              name={contractor.contactPersonName}
              email={contractor.contactPersonEmail}
              contactPhone={contractor.contactPersonPhone}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalculationsSubheader;
