import { createContext } from 'react';
import { StorageType, IUser, ITenant } from '../types';

const initialUserState = {
  email: '',
};
const initialTenantState = {
  name: '',
  logoUrl: '',
  fullScreenImageUrl: '',
  darkColor: '',
  lightColor: '',
};

interface GlobalContextType {
  user: IUser;
  setUser: (value: IUser, storage: StorageType) => void;
  removeUser: () => void;
  tenant: ITenant;
  setTenant: (value: ITenant) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
}

const GlobalContext = createContext<GlobalContextType>({
  user: initialUserState,
  setUser: (value: IUser, storage: StorageType) => {},
  removeUser: () => {},
  tenant: initialTenantState,
  setTenant: (value: ITenant) => {},
  loading: false,
  setLoading: (value: boolean) => {},
});

export { GlobalContext, initialUserState, initialTenantState };
