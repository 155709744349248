import React from 'react';

interface Props {
  children: React.ReactNode;
  show: boolean;
}

const SidePane = React.forwardRef((props: Props, ref: any) => {
  const { children, show } = props;

  const sidepaneStyle = {
    transform: show ? 'none' : 'translateX(100%)',
    WebkitTransform: show ? 'none' : 'translateX(100%)',
  };

  return (
    <div className="sidepane" style={sidepaneStyle} ref={ref}>
      <div className="sidepane-content">{children}</div>
    </div>
  );
});

export default SidePane;
