import { useContext, useEffect, useState } from 'react';

import { useApi } from '../api';

import { contractorAssignmentUrl } from '../../settings/api';

import { GlobalContext } from '../../contexts';

import { IContractorAssignment } from '../../types';
import { getStoredProject } from '../../utils';

export function useContractorsAssignmentGet() {
  const [contractorAssignments, setContractorAssignments] = useState<
    IContractorAssignment[]
  >([]);
  const {
    tenant: { name: tenantName },
    setLoading,
  } = useContext(GlobalContext);

  const { apiGet } = useApi();
  const { GUID: projectGUID } = getStoredProject();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response: IContractorAssignment[] = await apiGet(
        contractorAssignmentUrl(tenantName, projectGUID)
      );
      setContractorAssignments(response);
      setLoading(false);
    })();
  }, [apiGet, projectGUID, setLoading, tenantName]);

  return {
    contractorAssignments,
    setContractorAssignments,
  };
}
