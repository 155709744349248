import React, { useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import Sidebar from '../../components/Sidebar/Sidebar';
import PeriodTableRow from '../../components/OnAccount/PeriodTableRow';
import SubheaderActionRow from '../../components/Subheader/SubheaderActionRow';
import SubheaderNavigation from '../../components/Subheader/SubheaderNavigation';
import SkeletonTable from '../../components/Skeleton/SkeletonTable';
import {
  SidePane,
  SidePaneHeader,
  SidePaneFooter,
} from '../../components/SidePane';
import { ConfirmationModal, PopupModal } from '../../components/Modal';

import {
  useOutsideEvent,
  usePeriodsGet,
  usePeriodsPost,
  usePeriodsDelete,
} from '../../hooks';

import { IPeriod } from '../../types';

import {
  findLastMonthId,
  history,
  getStoredContractor,
  getStoredProject,
  setStoredPeriod,
} from '../../utils';

import moment from 'moment';

const initialCurrentPeriod = {
  id: '',
  contractorAssignmentId: '',
  projectId: '',
  contractorId: '',
  startDate: '',
  duration: '',
  sum: 0,
  tenantName: '',
  partitionKey: '',
};

function Periods() {
  const [showSidepane, setSidepane] = useState(false);
  const [showPopupModal, setPopupModal] = useState(false);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [openedPeriodMenu, setOpenedPeriodMenu] = useState('');
  const [isPeriodCreate, setIsPeriodCreate] = useState(true);
  const { register, handleSubmit, reset, errors } = useForm();
  const {
    periods,
    setPeriods,
    suggestedPeriodValue,
    setSuggesterPeriodValue,
    displayMessage,
    setDisplayMessage,
    periodSuggestedDate,
    contractorAssignmentId,
  } = usePeriodsGet();
  const { createOrUpdatePeriod, isSuccess, setSuccess } = usePeriodsPost();
  const { deletePeriod } = usePeriodsDelete();
  const { GUID: projectGUID, projectId: projectDisplayId } = getStoredProject();
  const contractor = getStoredContractor();
  const periodRef = useRef(null);
  const [currentPeriod, setCurrentPeriod] = useState<IPeriod>(
    initialCurrentPeriod
  );

  const handlePeriodCreate = async ({ startDate }: { startDate: string }) => {
    try {
      const { newPeriod } = await createOrUpdatePeriod({
        startDate,
        projectId: projectGUID,
        contractorId: contractor.id,
        sum: 0,
        duration: 'Month',
      });
      setPeriods([...periods, newPeriod]);
      setStoredPeriod(newPeriod);

      const lastMonthId = findLastMonthId(startDate, periods);
      history.push(
        `/projects/${projectGUID}/contractor-assignments/${contractorAssignmentId}/periods/${newPeriod.id}`,
        { lastMonthId }
      );
    } catch (error) {}
  };

  const handlePeriodUpdate = async ({ startDate }: { startDate: string }) => {
    try {
      const { newPeriod } = await createOrUpdatePeriod({
        startDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
        id: currentPeriod.id,
        sum: currentPeriod.sum,
        projectId: projectGUID,
        contractorId: contractor.id,
        duration: 'Month',
      });
      setSuggesterPeriodValue(
        moment(startDate).add(1, 'month').format('YYYY-MM')
      );
      const index = periods.indexOf(currentPeriod);
      periods.splice(index, 1, newPeriod);
      setStatesForSuccessResponse();
    } catch ({ status, message }) {
      setStatesForErrorResponse(message);
    }
  };

  const handlePeriodDelete = async () => {
    try {
      await deletePeriod(currentPeriod.id);

      const index = periods.indexOf(currentPeriod);
      periods.splice(index, 1);
      if (!periods.length) {
        setDisplayMessage('There is no any month yet.');
      }
      setSuggesterPeriodValue(
        periods.length
          ? moment(periods[0].startDate).add(1, 'month').format('YYYY-MM')
          : periodSuggestedDate
      );
      setStatesForSuccessResponse();
      setConfirmationModal(false);
    } catch ({ status, message }) {
      setStatesForErrorResponse(message);
      setConfirmationModal(false);
    }
  };

  const setPopupWithTimeout = (time: number) => {
    setPopupModal(true);
    setTimeout(() => {
      reset();
      setPopupModal(false);
    }, time);
  };

  const setStatesForSuccessResponse = () => {
    setSidepane(false);
    setSuccess(true);
    setPopupWithTimeout(1000);
  };

  const setStatesForErrorResponse = (message: string) => {
    setSidepane(false);
    setSuccess(false);
    reset();
  };

  const hideSidepane = () => {
    setSidepane(false);
  };

  const buttonAddPeriodClick = () => {
    setValuesForSidepane();
    setIsPeriodCreate(true);
    setSidepane(true);
  };

  const editPeriodClick = (period: IPeriod) => {
    setOpenedPeriodMenu('');
    setCurrentPeriod(period);
    setValuesForSidepane(period);
    setIsPeriodCreate(false);
    setSidepane(true);
  };

  const deletePeriodClick = (period: IPeriod) => {
    setOpenedPeriodMenu('');
    setCurrentPeriod(period);
    setSidepane(false);
    setConfirmationModal(true);
  };

  const setValuesForSidepane = (period?: IPeriod) => {
    reset({
      startDate: period
        ? moment(period.startDate).format('YYYY-MM')
        : suggestedPeriodValue,
      amount: period ? period.sum : '',
    });
  };

  const cancelConfirmationModal = () => {
    setConfirmationModal(false);
  };

  const changeOpenedPeriodMenu = (id: string) => {
    openedPeriodMenu === id ? setOpenedPeriodMenu('') : setOpenedPeriodMenu(id);
  };

  const buttonTasksClick = () => {
    history.push(
      `/projects/${projectGUID}/contractor-assignments/${contractorAssignmentId}/tasks`
    );
  };

  useOutsideEvent(periodRef, hideSidepane);

  return (
    <div className="periods">
      <Sidebar />
      <div className="periods-content">
        <SubheaderNavigation
          projectId={projectDisplayId}
          contractorId={contractor.displayId}
        />
        <div className="contractors-content__project-title">
          <h2>{contractor.displayName}</h2>
        </div>

        <SubheaderActionRow
          mainButtonAction="Add Month"
          additionalButtonAction="Tasks"
          title={displayMessage || periods.length ? 'Months' : undefined}
          handleMainButtonOnClick={buttonAddPeriodClick}
          handleAdditionalButtonOnClick={buttonTasksClick}
        />
        {!displayMessage && !periods.length ? (
          <SkeletonTable />
        ) : periods.length ? (
          <div className="periods-table">
            <div className="periods-table__header__date header">
              <span>Date</span>
            </div>
            <div className="periods-table__amount header">
              <span>Amount</span>
            </div>
            <div></div>
            <ul></ul>
            {periods.map((period) => (
              <PeriodTableRow
                key={period.id}
                period={period}
                periods={periods}
                handleMenuEditClick={editPeriodClick}
                handleMenuDeleteClick={deletePeriodClick}
                setOpenedPeriodMenu={changeOpenedPeriodMenu}
                openedPeriodMenu={openedPeriodMenu}
              />
            ))}
          </div>
        ) : (
          <div className="periods-display-message">
            <span>{displayMessage}</span>
          </div>
        )}
      </div>
      <SidePane show={showSidepane} ref={periodRef}>
        <Form
          onSubmit={handleSubmit(
            isPeriodCreate ? handlePeriodCreate : handlePeriodUpdate
          )}
          noValidate={true}
        >
          <SidePaneHeader
            title={isPeriodCreate ? 'Add month' : 'Edit month'}
            handleCancel={hideSidepane}
          />
          <Form.Label>Date</Form.Label>
          <Form.Control
            type="month"
            name="startDate"
            ref={register({
              required: true,
            })}
            isInvalid={errors.startDate ? true : false}
          />

          <SidePaneFooter
            confirmationButtonTitle={isPeriodCreate ? 'Create' : 'Update'}
            handleCancel={hideSidepane}
          />
        </Form>
        <PopupModal
          message={isSuccess ? 'Success.' : 'Something went wrong.'}
          success={isSuccess}
          show={showPopupModal}
          onHide={() => setPopupModal(false)}
        />
      </SidePane>
      <ConfirmationModal
        question="Are you sure you want delete this month?"
        show={showConfirmationModal}
        handleConfirm={handlePeriodDelete}
        handleCancel={cancelConfirmationModal}
      />
    </div>
  );
}

export default Periods;
