import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  Fragment,
} from 'react';
import { Button } from 'react-bootstrap';

import { GlobalContext } from '../../contexts';

import Sidebar from '../../components/Sidebar/Sidebar';
import SubheaderNavigation from '../../components/Subheader/SubheaderNavigation';
import SkeletonTable from '../../components/Skeleton/SkeletonTable';
import TasksTableRow from '../../components/OnAccount/TasksTableRow';
import ListAllTasks from '../../components/OnAccount/ListAllTasks';
import ListAllTaskGroups from '../../components/OnAccount/ListAllTaskGroups';
import SkeletonLine from '../../components/Skeleton/SkeletonLine';
import { ModalWithProgressBar } from '../../components/Modal';

import {
  useTaskGroupsGet,
  useTaskGroupsPost,
  useTasksGet,
  useTasksPost,
} from '../../hooks';

import {
  fillOutTaskGroups,
  getStoredContractor,
  getStoredProject,
  history,
} from '../../utils';

import { IMainTaskGroup, IIsCollapsedTaskGroup } from '../../types';

import XLSX from 'xlsx';

enum ExcelRow {
  ID = 'ID',
  taskGroup = 'Task Group',
  subGroup = 'Sub Group',
  taskTitle = 'Task Title',
  value = 'Value',
}

function Tasks() {
  const [importPercentage, setImportPercentage] = useState(0);
  const [
    collapsedTaskGroups,
    setCollapsedTaskGroups,
  ] = useState<IIsCollapsedTaskGroup>({});
  const [isProgressVisible, setProgressVisibility] = useState(false);
  const importInputRef = useRef<HTMLInputElement>(null);
  const { tasks } = useTasksGet();
  const { taskGroups, displayMessage } = useTaskGroupsGet();
  const { addTaskGroup } = useTaskGroupsPost();
  const { addTask } = useTasksPost();
  const { GUID: projectGUID, projectId: projectDisplayId } = getStoredProject();
  const { setLoading } = useContext(GlobalContext);
  const contractor = getStoredContractor();

  useEffect(() => {
    let collapsedTaskGroupObject: IIsCollapsedTaskGroup = {};
    taskGroups.forEach((taskGroup) => {
      const object: IIsCollapsedTaskGroup = {};
      object[taskGroup.id] = true;
      collapsedTaskGroupObject = { ...collapsedTaskGroupObject, ...object };
    });
    setCollapsedTaskGroups(collapsedTaskGroupObject);
  }, [taskGroups]);

  const handleImportButtonClick = (e: any) => {
    importInputRef.current?.click();
  };

  const handleFileImport = (e: any) => {
    const excelFile: Blob = e.target.files[0];
    const fileReader = new FileReader();

    fileReader.onload = async (e: any) => {
      const readedData = XLSX.read(e.target.result, { type: 'binary' });
      const worksheetName = readedData.SheetNames[0];
      const worksheet = readedData.Sheets[worksheetName];

      const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 0 });
      setProgressVisibility(true);
      await importDataIntoDatabase(parsedData);
      setTimeout(() => {
        refreshPage();
      }, 500);
    };

    fileReader.readAsBinaryString(excelFile);
  };

  async function importDataIntoDatabase(dataFromExcel: any) {
    const { ID, taskGroup, subGroup, taskTitle, value } = ExcelRow;
    const dataLength = dataFromExcel.length;
    let lastAddedTaskGroupId = '';
    let lastAddedGroupIdForTask = '';

    for (const row of dataFromExcel) {
      const percentage = (row['__rowNum__'] * 100) / dataLength;
      setTimeout(() => {
        setImportPercentage(percentage);
      }, 100);

      try {
        setLoading(true);
        if (row[taskGroup]) {
          const response = await addTaskGroup({
            displayId: row[ID],
            name: row[taskGroup],
          });
          lastAddedTaskGroupId = response;
          lastAddedGroupIdForTask = response;
        } else if (row[subGroup]) {
          const response = await addTaskGroup({
            displayId: row[ID],
            name: row[subGroup],
            taskGroupId: lastAddedTaskGroupId,
          });
          lastAddedGroupIdForTask = response;
        } else if (row[taskTitle]) {
          await addTask({
            displayId: row[ID],
            name: row[taskTitle],
            projectId: projectGUID,
            value: row[value],
            taskGroupId: lastAddedGroupIdForTask,
          });
        }
        setLoading(true);
      } catch (error) {
        setLoading(false);
      }
    }
  }

  let mainTaskGroups: IMainTaskGroup[] = [];

  if (taskGroups.length) {
    mainTaskGroups = fillOutTaskGroups(taskGroups, tasks);
  }

  const changeOpenedTaskGroup = (id: string) => {
    const newObject: IIsCollapsedTaskGroup = {};
    newObject[id] = !collapsedTaskGroups[id];
    setCollapsedTaskGroups({ ...collapsedTaskGroups, ...newObject });
  };

  const refreshPage = () => {
    history.go(0);
  };

  const onHideProgressModal = () => {};

  return (
    <div className="tasks">
      <Sidebar />
      <div className="tasks-content">
        <SubheaderNavigation
          projectId={projectDisplayId}
          contractorId={contractor.displayId}
        />
        <div className="contractors-content__project-title">
          <h2>{contractor.displayName}</h2>
          <>
            <Button variant="danger" onClick={handleImportButtonClick}>
              Import
            </Button>
            <input
              ref={importInputRef}
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileImport}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </>
        </div>
        <div className="subheader-action-row">
          <div className="subheader-action-row__title">
            <h4>
              {displayMessage || mainTaskGroups.length ? (
                'Tasks'
              ) : (
                <SkeletonLine />
              )}
            </h4>
          </div>
        </div>
        {!displayMessage && !mainTaskGroups.length ? (
          <SkeletonTable />
        ) : mainTaskGroups.length ? (
          <div className="tasks-table">
            <div className="tasks-table__header__id header">ID</div>
            <div className="tasks-table__name header">Name</div>
            <div className="tasks-table__value right-alignment header">
              Value
            </div>
            <div></div>

            {mainTaskGroups.map((mainTaskGroup: IMainTaskGroup) => (
              <Fragment key={mainTaskGroup.id}>
                <TasksTableRow
                  id={mainTaskGroup.id}
                  displayId={mainTaskGroup.displayId}
                  name={mainTaskGroup.name}
                  value={mainTaskGroup.value}
                  isMainTaskGroup={!mainTaskGroup.taskGroupId ? true : false}
                  setOpenedTaskGroup={changeOpenedTaskGroup}
                  isOpenedTaskGroup={collapsedTaskGroups[mainTaskGroup.id]}
                  isRowVisible={true}
                />
                {mainTaskGroup.taskGroups.length ? (
                  <ListAllTaskGroups
                    taskGroups={mainTaskGroup.taskGroups}
                    collapsedTaskGroups={collapsedTaskGroups}
                    isOpenedTaskGroup={collapsedTaskGroups[mainTaskGroup.id]}
                    changeOpenedTaskGroup={changeOpenedTaskGroup}
                  />
                ) : (
                  <ListAllTasks
                    tasks={mainTaskGroup.tasks}
                    isOpenedTaskGroup={collapsedTaskGroups[mainTaskGroup.id]}
                  />
                )}
              </Fragment>
            ))}
          </div>
        ) : (
          <div className="periods-display-message">
            <span>{displayMessage}</span>
          </div>
        )}
        <ModalWithProgressBar
          isProgressModalVisible={isProgressVisible}
          percentageOfImport={importPercentage}
          onHide={onHideProgressModal}
          handleCancelButtonClick={refreshPage}
        />
      </div>
    </div>
  );
}

export default Tasks;
