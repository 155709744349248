import React, { useContext, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { GlobalContext } from '../../contexts';

import { useHover, useOutsideEvent } from '../../hooks/eventHandler';

import { IPeriod } from '../../types';
import TableItemMore from '../Items/TableItemMore';
import TableContextMenu from '../Menu/TableContextMenu';
import {
  formatNumber,
  findLastMonthId,
  getStoredProject,
  setStoredPeriod,
} from '../../utils';

import moment from 'moment';

interface Props {
  period: IPeriod;
  periods: IPeriod[];
  setOpenedPeriodMenu: (id: string) => void;
  handleMenuEditClick: (period: IPeriod) => void;
  handleMenuDeleteClick: (period: IPeriod) => void;
  openedPeriodMenu: string;
}

function PeriodTableRow(props: Props) {
  const [isRowHovered, setRowHover] = useState(false);
  const { tenant } = useContext(GlobalContext);
  const {
    period,
    periods,
    setOpenedPeriodMenu,
    handleMenuEditClick,
    handleMenuDeleteClick,
    openedPeriodMenu,
  } = props;
  const {
    contractorAssignmentId,
  }: { contractorAssignmentId: string } = useParams();
  const { GUID: projectGUID } = getStoredProject();
  const history = useHistory();
  const dateRef = useRef(null);
  const amountRef = useRef(null);
  const menuRef = useRef(null);
  const showMenu = openedPeriodMenu === period.id;

  const dateStyle = isRowHovered
    ? {
        borderLeft: `7px solid ${tenant.darkColor}`,
      }
    : {};

  const handleRowHover = (hasHover: boolean) => {
    setRowHover(hasHover);
  };

  const handleClickMore = () => {
    setOpenedPeriodMenu(period.id);
  };

  const handleEditClick = () => {
    handleMenuEditClick(period);
  };

  const handleDeleteClick = () => {
    handleMenuDeleteClick(period);
  };

  const handleMenuOutsideClick = () => {
    if (showMenu) setOpenedPeriodMenu('');
  };

  const handlePeriodRowClick = () => {
    const lastMonthId = findLastMonthId(period.startDate, periods);
    setStoredPeriod(period);
    history.push(
      `/projects/${projectGUID}/contractor-assignments/${contractorAssignmentId}/periods/${period.id}`,
      { lastMonthId }
    );
  };

  useOutsideEvent(menuRef, handleMenuOutsideClick);

  useHover(dateRef, handleRowHover);
  useHover(amountRef, handleRowHover);

  return (
    <>
      <div
        className="periods-table__date data-row bordered"
        onClick={handlePeriodRowClick}
        style={dateStyle}
        ref={dateRef}
      >
        <i className="material-icons">insert_chart</i>
        <div className="periods-table__date--flex data-row">
          <span>{moment(period.startDate).format('YYYY')}</span>
          <span>{moment(period.startDate).format('MMMM')}</span>
        </div>
      </div>
      <div
        className="periods-table__amount data-row"
        onClick={handlePeriodRowClick}
        ref={amountRef}
      >
        <span>{formatNumber(period.sum)}</span>
      </div>

      <TableItemMore
        clickHandler={handleClickMore}
        isMenuOpened={showMenu}
        handleHover={handleRowHover}
      />
      <TableContextMenu
        show={showMenu}
        ref={menuRef}
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
        handleHover={handleRowHover}
      />
    </>
  );
}

export default PeriodTableRow;
