import { USER, TENANT, TOKEN } from '../constants/storage';
import { ITenant, IUser } from '../types';
import { StorageType } from '../types/StorageType';

function getStoredUser() {
  const storedUser = localStorage.getItem(USER)
    ? localStorage.getItem(USER)
    : sessionStorage.getItem(USER);

  return storedUser ? JSON.parse(storedUser) : {};
}

function getStoredTenant() {
  const storedTenant = localStorage.getItem(TENANT);

  return storedTenant ? JSON.parse(storedTenant) : {};
}

function getStoredToken() {
  return sessionStorage.getItem(TOKEN) || localStorage.getItem(TOKEN);
}

function setStoredUser(user: IUser, storage: StorageType) {
  return storage === StorageType.LocalStorage
    ? localStorage.setItem(USER, JSON.stringify(user))
    : sessionStorage.setItem(USER, JSON.stringify(user));
}

function setStoredTenant(tenant: ITenant) {
  localStorage.setItem(TENANT, JSON.stringify(tenant));
}

function setStoredToken(token: string, storage: StorageType) {
  return storage === StorageType.LocalStorage
    ? localStorage.setItem(TOKEN, token)
    : sessionStorage.setItem(TOKEN, token);
}

function removeStoredUser() {
  localStorage.removeItem(USER);
  sessionStorage.removeItem(USER);
}

function removeStoredTenant() {
  localStorage.removeItem(TENANT);
}

function removeStoredToken() {
  localStorage.removeItem(TOKEN);
  sessionStorage.removeItem(TOKEN);
}

export {
  getStoredUser,
  getStoredTenant,
  getStoredToken,
  setStoredUser,
  setStoredTenant,
  setStoredToken,
  removeStoredUser,
  removeStoredTenant,
  removeStoredToken,
};
