import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';

import { GlobalContext } from '../../contexts';

interface Props {
  handleCancel: () => void;
  cancelationButtonClassName?: string;
  cancelationButtonTitle?: string;
  confirmationButtonTitle?: string;
}

function SidePaneFooter(props: Props) {
  const {
    handleCancel,
    cancelationButtonClassName,
    cancelationButtonTitle,
    confirmationButtonTitle,
  } = props;
  const { loading } = useContext(GlobalContext);
  return (
    <div className="sidepane-footer">
      <Button
        className="sidepane-footer__btn-cancel"
        size="sm"
        variant={
          cancelationButtonClassName
            ? cancelationButtonClassName
            : 'outline-secondary'
        }
        onClick={handleCancel}
        disabled={loading}
      >
        {cancelationButtonTitle ? cancelationButtonTitle : 'Cancel'}
      </Button>
      <Button
        className="sidepane-footer__btn-confirm"
        size="sm"
        type="submit"
        variant="success"
        disabled={loading}
      >
        {confirmationButtonTitle ? confirmationButtonTitle : 'Create'}
      </Button>
    </div>
  );
}

export default SidePaneFooter;
