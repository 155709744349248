import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { getStoredToken } from '../../utils';

function AuthorizedRoute(props: RouteProps) {
  const token = getStoredToken();

  if (!token) {
    return <Redirect to="login" />;
  }

  return <Route {...props} />;
}

export default AuthorizedRoute;
