import { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useApi } from '../api';

import { taskGroupUrl } from '../../settings/api';
import { GlobalContext } from '../../contexts';

import { ICreateTaskGroup } from '../../types';

export function useTaskGroupsPost() {
  const [isSuccess, setSuccess] = useState(false);

  const {
    tenant: { name: tenantName },
    setLoading,
  } = useContext(GlobalContext);
  const {
    contractorAssignmentId,
  }: { contractorAssignmentId: string } = useParams();

  const { apiPost } = useApi();

  const addTaskGroup = useCallback(
    async (taskGroup: ICreateTaskGroup) => {
      try {
        setLoading(true);

        const { id } = await apiPost(
          taskGroupUrl(tenantName, contractorAssignmentId),
          taskGroup
        );

        setSuccess(true);
        setLoading(false);

        return id;
      } catch (error) {
        setSuccess(false);
        setLoading(false);
        throw error;
      }
    },
    [apiPost, tenantName, contractorAssignmentId, setLoading]
  );

  return {
    addTaskGroup,
    isSuccess,
    setSuccess,
  };
}
