import { useCallback, useContext, useState } from 'react';

import { useApi } from '../api';

import { projectUrl } from '../../settings/api';

import { IProject } from '../../types';
import { GlobalContext } from '../../contexts';
import { setStoredProject } from '../../utils';

export function useProjectsPost() {
  const [isSuccess, setSuccess] = useState(false);

  const {
    tenant: { name: tenantName },
    setLoading,
  } = useContext(GlobalContext);

  const { apiPost } = useApi();

  const addProject = useCallback(
    async (project: IProject) => {
      try {
        setLoading(true);

        const { id } = await apiPost(projectUrl(tenantName), project);
        setSuccess(true);
        setLoading(false);

        project.id = id;

        return project;
      } catch (error) {
        setSuccess(false);
        setLoading(false);
        throw error;
      }
    },
    [apiPost, setLoading, tenantName]
  );

  const updateProject = useCallback(
    async (project: IProject) => {
      try {
        setLoading(true);
        await apiPost(projectUrl(tenantName), project);
        setStoredProject({
          GUID: project.id,
          projectId: project.displayId,
          projectDisplayName: project.displayName,
        });

        setLoading(false);
      } catch (error) {
        setLoading(false);
        throw error;
      }
    },
    [apiPost, setLoading, tenantName]
  );

  return { addProject, updateProject, isSuccess };
}
