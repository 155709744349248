import React, { useState, useContext, useRef, CSSProperties } from 'react';

import { GlobalContext } from '../../contexts';

import PopupMenu from '../Menu/PopupMenu';
import PopupMenuItem from './PopupMenuItem';
import { ReactComponent as LogoutIcon } from '../../assets/logout.svg';

import { useOutsideEvent } from '../../hooks';

import { adjustColor } from '../../utils';

function UserProfileItem() {
  const [isPopupMenuVisible, setPopupMenuVisibility] = useState(false);
  const { tenant } = useContext(GlobalContext);
  const menuRef = useRef(null);
  const userIconBackground = adjustColor(tenant.darkColor, 200);

  const userIconStyle = {
    backgroundColor: userIconBackground,
    pointerEvents: isPopupMenuVisible ? 'none' : 'auto',
  } as CSSProperties;

  const handleUserIconClick = () => {
    setPopupMenuVisibility(!isPopupMenuVisible);
  };

  const handleMenuOutsideEvent = () => {
    setPopupMenuVisibility(false);
  };

  useOutsideEvent(menuRef, handleMenuOutsideEvent);

  return (
    <>
      <div
        className="sidebar__footer__user"
        style={userIconStyle}
        onClick={handleUserIconClick}
      >
        <i
          className="material-icons"
          style={{
            color: tenant.darkColor,
          }}
        >
          person
        </i>
      </div>
      <PopupMenu show={isPopupMenuVisible} ref={menuRef}>
        <PopupMenuItem title="Logout" path="/logout">
          <LogoutIcon className="popup-menu__item__icon" />
        </PopupMenuItem>
      </PopupMenu>
    </>
  );
}

export default UserProfileItem;
