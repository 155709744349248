import React, {
  useState,
  useContext,
  useRef,
  CSSProperties,
  useEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';

import Sidebar from '../../components/Sidebar/Sidebar';
import ContractorsTableRow from '../../components/Contractors/ContractorsTableRow';
import PopupModal from '../../components/Modal/PopupModal';
import SkeletonTable from '../../components/Skeleton/SkeletonTable';
import SubheaderNavigation from '../../components/Subheader/SubheaderNavigation';
import SubheaderActionRow from '../../components/Subheader/SubheaderActionRow';
import {
  SidePane,
  SidePaneHeader,
  SidePaneFooter,
} from '../../components/SidePane';
import { ConfirmationModal } from '../../components/Modal';
import { ContractorsSubheaderActions } from '../../components/Contractors';

import { GlobalContext } from '../../contexts';

import {
  useContractorsDelete,
  useContractorsGet,
  useContractorsPost,
  useOutsideEvent,
  useProjectsPost,
  useProjectsDelete,
  usePeriodSumPerContractor,
  useContractorsAssignmentGet,
} from '../../hooks/';

import {
  IContractor,
  IContractorForm,
  IPeriodSumPerContractor,
  IProjectEditForm,
} from '../../types/';

import { getStoredProject } from '../../utils';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';

const initialCurrentContractor = {
  id: '',
  displayId: '',
  displayName: '',
  contactPersonName: '',
  contactPersonEmail: '',
  contactPersonPhone: '',
  tenantName: '',
  partitionKey: '',
};

function Contractors() {
  const [contractorSidePane, setContractorSidePane] = useState(false);
  const [projectSidePane, setProjectSidePane] = useState(false);
  const [
    periodSumPerContractor,
    setPeriodSumPerContractor,
  ] = useState<IPeriodSumPerContractor | null>(null);
  const [popupModal, setPopupModal] = useState(false);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [openedContractorMenu, setOpenedContractorMenu] = useState('');
  const [isContractorCreate, setIsContractorCreate] = useState(true);
  const [isContractorConfirmation, setContractorConfirmation] = useState(true);
  const [currentContractor, setCurrentContractor] = useState<IContractor>(
    initialCurrentContractor
  );

  const {
    contractors,
    setContractors,
    displayMessage,
    setDisplayMessage,
  } = useContractorsGet();
  const {
    addContractor,
    updateContractor,
    updateContractorAssignment,
    isSuccess,
    setSuccess,
  } = useContractorsPost();
  const { deleteContractor } = useContractorsDelete();
  const { updateProject } = useProjectsPost();
  const { deleteProject } = useProjectsDelete();
  const { getPeriodSumPerContractor } = usePeriodSumPerContractor();
  const {
    contractorAssignments,
    setContractorAssignments,
  } = useContractorsAssignmentGet();
  const { register, handleSubmit, reset, errors } = useForm();
  const { tenant, setLoading } = useContext(GlobalContext);
  const contractorsRef = useRef(null);

  const {
    GUID: projectGUID,
    projectDisplayName,
    projectId,
  } = getStoredProject();

  const editProjectIconStyle = {
    pointerEvents: projectSidePane ? 'none' : 'auto',
  } as CSSProperties;

  useEffect(() => {
    (async () => {
      setPeriodSumPerContractor(await getPeriodSumPerContractor());
    })();
  }, [getPeriodSumPerContractor]);

  const handleContractorCreate = async (data: IContractorForm) => {
    try {
      const trade = data.trade;
      delete data.trade;
      const { newContractor, newContractorAssignment } = await addContractor(
        data as IContractor,
        trade || ''
      );
      setContractors([...contractors, newContractor]);
      setContractorAssignments([
        ...contractorAssignments,
        newContractorAssignment,
      ]);
      setContractorSidePane(false);
      setPopupWithTimeout(1000);
    } catch (error) {
      setContractorSidePane(false);
      setPopupModal(true);
      reset();
    }
  };

  const handleContractorUpdate = async (data: IContractorForm) => {
    try {
      const contractorAssignment = findContractorAssignment(
        currentContractor.id
      );
      const trade = data.trade || '';
      delete data.trade;

      const updatedContractor = await updateContractor({
        id: currentContractor.id,
        ...data,
      });
      if (contractorAssignment && contractorAssignment.trade !== trade) {
        updateContractorAssignment({
          ...contractorAssignment,
          trade,
        });
        contractorAssignment.trade = trade;
      }
      const index = contractors.indexOf(currentContractor);
      contractors.splice(index, 1, updatedContractor);
      setContractorSidePane(false);
      setPopupWithTimeout(1000);
    } catch (error) {
      setContractorSidePane(false);
      setPopupModal(true);
      reset();
    }
  };

  const handleContractorDelete = async () => {
    try {
      const contractorAssignment = findContractorAssignment(
        currentContractor.id
      );

      await deleteContractor(contractorAssignment?.id || '');
      const index = contractors.indexOf(currentContractor);
      contractors.splice(index, 1);

      if (!contractors.length) {
        setDisplayMessage('There are not yet any contractors on this case.');
      }
      setSuccess(true);
      setConfirmationModal(false);
      setPopupWithTimeout(1000);
    } catch (error) {
      setSuccess(false);
      setPopupModal(true);
      setConfirmationModal(false);
      reset();
    }
  };

  const handleProjectUpdate = async ({
    newProjectId,
    newProjectDisplayName,
  }: IProjectEditForm) => {
    try {
      await updateProject({
        id: projectGUID,
        displayId: newProjectId,
        displayName: newProjectDisplayName,
      });
      setProjectSidePane(false);
      setSuccess(true);
      setPopupWithTimeout(1000);
    } catch (error) {
      setProjectSidePane(false);
      setSuccess(false);
      setPopupModal(true);
      reset();
    }
  };

  const handleProjectDelete = async () => {
    try {
      await deleteProject();
      setConfirmationModal(false);
      setSuccess(true);
      setPopupModal(true);
    } catch (error) {
      setProjectSidePane(false);
      setSuccess(false);
      setConfirmationModal(false);
      setPopupModal(true);
      reset();
    }
  };

  const setPopupWithTimeout = (time: number) => {
    setPopupModal(true);
    setTimeout(() => {
      reset();
      setPopupModal(false);
    }, time);
  };

  const handleNewPeriodSumTime = async (startDate: string, endDate: string) => {
    try {
      setLoading(true);

      setPeriodSumPerContractor(
        await getPeriodSumPerContractor(startDate, endDate)
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const hideSidepane = () => {
    contractorSidePane
      ? setContractorSidePane(false)
      : setProjectSidePane(false);
  };
  const buttonAddContractorClick = () => {
    setValuesForContractorSidePane();
    setIsContractorCreate(true);
    setContractorSidePane(true);
  };

  const editContractorClick = (contractor: IContractor, trade?: string) => {
    setOpenedContractorMenu('');
    setCurrentContractor(contractor);
    setValuesForContractorSidePane(contractor, trade);
    setIsContractorCreate(false);
    setContractorSidePane(true);
  };

  const deleteContractorClick = (contractor: IContractor) => {
    setOpenedContractorMenu('');
    setCurrentContractor(contractor);
    setContractorSidePane(false);
    setContractorConfirmation(true);
    setConfirmationModal(true);
  };

  const editProjectIconClick = () => {
    setValuesForProjectSidePane();
    setProjectSidePane(true);
  };

  const deleteProjectClick = () => {
    setProjectSidePane(false);
    setContractorConfirmation(false);
    setConfirmationModal(true);
  };

  const cancelConfirmationModal = () => {
    setConfirmationModal(false);
  };

  const setValuesForContractorSidePane = (
    contractor?: IContractor,
    trade?: string
  ) => {
    reset({
      displayId: contractor ? contractor.displayId : '',
      trade: trade || '',
      displayName: contractor ? contractor.displayName : '',
      contactPersonName: contractor ? contractor.contactPersonName : '',
      contactPersonEmail: contractor ? contractor.contactPersonEmail : '',
      contactPersonPhone: contractor ? contractor.contactPersonPhone : '',
    });
  };

  const setValuesForProjectSidePane = () => {
    reset({
      newProjectId: projectId,
      newProjectDisplayName: projectDisplayName,
    });
  };

  const changeOpenedContractorMenu = (id: string) => {
    openedContractorMenu === id
      ? setOpenedContractorMenu('')
      : setOpenedContractorMenu(id);
  };

  const hidePopupModal = () => {
    setPopupModal(false);
  };

  useOutsideEvent(contractorsRef, hideSidepane);

  return (
    <div className="contractors">
      <Sidebar />
      <div className="contractors-content">
        <SubheaderNavigation projectId={projectId} />
        <div className="contractors-content__subheader-title-actions">
          <div
            className="contractors-content__subheader-title-actions__project-title"
            style={editProjectIconStyle}
          >
            <h2>{projectDisplayName}</h2>
            <div
              className="contractors-content__subheader-title-actions__project-title__edit"
              onClick={editProjectIconClick}
            >
              <EditIcon />
            </div>
          </div>
          <ContractorsSubheaderActions
            changePeriodTime={handleNewPeriodSumTime}
            isButtonDisabled={
              periodSumPerContractor === null || !contractors.length
            }
          />
        </div>

        <SubheaderActionRow
          mainButtonAction="Add Contractor"
          title={
            displayMessage ||
            (contractors.length && periodSumPerContractor !== null)
              ? 'Contractors'
              : undefined
          }
          handleMainButtonOnClick={buttonAddContractorClick}
        />

        {!displayMessage &&
        (!contractors.length || periodSumPerContractor === null) ? (
          <SkeletonTable />
        ) : contractors.length && periodSumPerContractor !== null ? (
          <div className="contractors-table">
            <div className="contractors-table__header__id header">ID</div>
            <div className="contractors-table__trade header">Trade</div>
            <div className="contractors-table__name header">Name</div>
            <div className="contractors-table__sum header">Sum</div>
            <div className="contractors-table__contact header">
              Contact Person
            </div>
            <div className="contractors-table__email header">Email</div>
            <div className="contractors-table__phone header">Phone</div>
            <div className="header"></div>
            <ul className="header"></ul>

            {contractors.map((contractor) => {
              const contractorAssignment = findContractorAssignment(
                contractor.id
              );
              return (
                <ContractorsTableRow
                  key={contractor.id}
                  contractor={contractor}
                  contractorAssignment={contractorAssignment}
                  periodSumPerContractor={periodSumPerContractor}
                  hoverColor={tenant.darkColor}
                  openedContractorMenu={openedContractorMenu}
                  setOpenedContractorMenu={changeOpenedContractorMenu}
                  handleMenuEditClick={editContractorClick}
                  handleMenuDeleteClick={deleteContractorClick}
                />
              );
            })}
          </div>
        ) : (
          <div className="contractors-display-message">
            <span>{displayMessage}</span>
          </div>
        )}
      </div>
      <SidePane
        show={contractorSidePane || projectSidePane}
        ref={contractorsRef}
      >
        {contractorSidePane ? (
          <Form
            onSubmit={handleSubmit(
              isContractorCreate
                ? handleContractorCreate
                : handleContractorUpdate
            )}
          >
            <SidePaneHeader
              title={isContractorCreate ? 'Add Contractor' : 'Edit Contractor'}
              handleCancel={hideSidepane}
            />
            <Form.Label>ID</Form.Label>
            <Form.Control
              type="text"
              id="id"
              name="displayId"
              ref={register({
                required: true,
              })}
              isInvalid={errors.displayId ? true : false}
            />

            <Form.Label>Business Registration Number</Form.Label>
            <Form.Control
              type="number"
              id="registrationNumber"
              name="registrationNumber"
              ref={register({
                required: false,
              })}
            />

            <Form.Label>Business Name</Form.Label>
            <Form.Control
              type="text"
              id="businessName"
              name="displayName"
              ref={register({
                required: true,
              })}
              isInvalid={errors.displayName ? true : false}
            />

            <Form.Label>Trade</Form.Label>
            <Form.Control
              type="text"
              id="trade"
              name="trade"
              ref={register()}
            />

            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              id="fullName"
              name="contactPersonName"
              ref={register()}
            />

            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              id="email"
              name="contactPersonEmail"
              ref={register()}
            />

            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              id="phoneNumber"
              name="contactPersonPhone"
              ref={register()}
            />

            <SidePaneFooter
              handleCancel={hideSidepane}
              confirmationButtonTitle={isContractorCreate ? 'Create' : 'Update'}
            />
          </Form>
        ) : (
          <Form onSubmit={handleSubmit(handleProjectUpdate)}>
            <SidePaneHeader title="Edit case" handleCancel={hideSidepane} />
            <Form.Label>Case ID</Form.Label>
            <Form.Control
              type="text"
              id="projectId"
              name="newProjectId"
              ref={register({
                required: true,
              })}
              isInvalid={errors.newProjectId ? true : false}
            />
            <Form.Label>Display name</Form.Label>
            <Form.Control
              type="text"
              id="projectDisplayName"
              name="newProjectDisplayName"
              ref={register({
                required: true,
              })}
              isInvalid={errors.newProjectDisplayName ? true : false}
            />

            <SidePaneFooter
              handleCancel={deleteProjectClick}
              cancelationButtonClassName={'outline-danger'}
              cancelationButtonTitle={'Delete'}
              confirmationButtonTitle={'Update'}
            />
          </Form>
        )}
        <PopupModal
          message={isSuccess ? 'Success.' : 'Something went wrong.'}
          success={isSuccess}
          show={popupModal}
          onHide={hidePopupModal}
        />
      </SidePane>
      <ConfirmationModal
        question={
          isContractorConfirmation
            ? 'Are you sure you want delete this contractor?'
            : 'Are you sure you want delete this case?'
        }
        show={showConfirmationModal}
        handleConfirm={
          isContractorConfirmation
            ? handleContractorDelete
            : handleProjectDelete
        }
        handleCancel={cancelConfirmationModal}
      />
    </div>
  );

  function findContractorAssignment(contractorId: string) {
    return contractorAssignments.find(
      (contractorAssignment) =>
        contractorAssignment.contractorId === contractorId
    );
  }
}

export default Contractors;
