import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import moment from 'moment';

interface Props {
  changePeriodTime: (startDate: string, endDate: string) => Promise<void>;
  isButtonDisabled: boolean;
}

function ContractorsSubheaderActions(props: Props) {
  const [activeButton, setActiveButton] = useState(0);
  const { changePeriodTime, isButtonDisabled } = props;

  const buttonsData = [
    {
      id: 0,
      title: 'All',
    },
    {
      id: 1,
      title: 'This month',
    },
    {
      id: 2,
      title: 'Last month',
    },
  ];

  const handleButtonActivity = async (id: number) => {
    if (activeButton === id) return;
    setActiveButton(id);

    let startDate = '';
    let endDate = '';

    switch (id) {
      case 0:
        break;
      case 1:
        startDate = moment().startOf('month').format('YYYY-MM-DD');
        endDate = moment()
          .add(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD');
        break;
      case 2:
        startDate = moment()
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD');
        endDate = moment(startDate).add(1, 'month').format('YYYY-MM-DD');
        break;
    }

    await changePeriodTime(startDate, endDate);
  };

  return (
    <div className="contractors-content__subheader-title-actions__actions">
      {buttonsData.map((item) => (
        <Button
          key={item.id}
          disabled={isButtonDisabled}
          variant="light"
          className={activeButton === item.id ? 'active' : ''}
          onClick={() => handleButtonActivity(item.id)}
        >
          {item.title}
        </Button>
      ))}
    </div>
  );
}

export default ContractorsSubheaderActions;
