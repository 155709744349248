import React, { useState, useEffect, useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';

import Sidebar from '../../components/Sidebar/Sidebar';
import ProjectCard from '../../components/Card/ProjectCard';
import {
  SidePane,
  SidePaneHeader,
  SidePaneFooter,
} from '../../components/SidePane';
import PopupModal from '../../components/Modal/PopupModal';
import Subheader from '../../components/Subheader/Subheader';
import SkeletonCards from '../../components/Skeleton/SkeletonCards';

import { GlobalContext } from '../../contexts';

import { useApi, useProjectsPost, useOutsideEvent } from '../../hooks';

import { IProjectForm, IProject } from '../../types';
import { projectUrl } from '../../settings/api';

function Projects() {
  const [sidepane, setSidepane] = useState(false);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [popupModal, setPopupModal] = useState(false);
  const { addProject, isSuccess } = useProjectsPost();
  const [displayMessage, setDisplayMessage] = useState('');
  const { register, handleSubmit, reset, errors } = useForm();
  const { apiGet } = useApi();
  const { tenant, setLoading } = useContext(GlobalContext);
  const { name: tenantName } = tenant;
  const projectRef = useRef(null);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response: IProject[] = await apiGet(projectUrl(tenantName));
      if (!response.length) {
        setDisplayMessage('There are not yet any cases for this tenant.');
      } else {
        setProjects(response);
      }
      setLoading(false);
    })();
  }, [apiGet, tenantName, setLoading]);

  const handleCreateProject = async (data: IProjectForm) => {
    try {
      const newProject = await addProject({ id: '', ...data });
      setProjects([...projects, newProject]);
      setSidepane(false);
      setPopupModal(true);
      setTimeout(() => {
        reset();
        setPopupModal(false);
      }, 500);
    } catch ({ status, message }) {
      setSidepane(false);
      setPopupModal(true);
      reset();
    }
  };

  const hideSidepane = () => {
    setSidepane(false);
  };

  const buttonCreateClick = () => {
    reset();
    setSidepane(true);
  };

  const hidePopupModal = () => {
    setPopupModal(false);
  };

  useOutsideEvent(projectRef, hideSidepane);

  return (
    <div className="projects">
      <Sidebar />
      <div className="projects-content">
        <Subheader
          title="Cases"
          buttonAction="Create Case"
          handleOnClick={buttonCreateClick}
        />
        {!displayMessage && !projects.length ? (
          <SkeletonCards />
        ) : projects.length ? (
          <div className="projects-cards">
            {projects.map((project) => {
              return (
                <ProjectCard
                  id={project.id}
                  projectId={project.displayId}
                  projectDisplayName={project.displayName}
                  key={project.id}
                />
              );
            })}
          </div>
        ) : (
          <div className="projects-display-message">
            <span>{displayMessage}</span>
          </div>
        )}

        <SidePane show={sidepane} ref={projectRef}>
          <Form onSubmit={handleSubmit(handleCreateProject)}>
            <SidePaneHeader title="Add case" handleCancel={hideSidepane} />
            <Form.Label>Case ID</Form.Label>
            <Form.Control
              type="text"
              id="id"
              name="displayId"
              ref={register({
                required: true,
              })}
              isInvalid={errors.displayId ? true : false}
            />
            <Form.Label>Display name</Form.Label>
            <Form.Control
              type="text"
              id="displayName"
              name="displayName"
              ref={register({
                required: true,
              })}
              isInvalid={errors.displayName ? true : false}
            />

            <SidePaneFooter handleCancel={hideSidepane} />
          </Form>
        </SidePane>
        <PopupModal
          message={isSuccess ? 'Success.' : 'Something went wrong.'}
          success={isSuccess}
          show={popupModal}
          onHide={hidePopupModal}
        />
      </div>
    </div>
  );
}

export default Projects;
