import { baseOnAccountUrl } from '../../../baseApi';

export const periodUrl = (tenantName: string, contractorAssignmentId: string) =>
  `${baseOnAccountUrl}/${tenantName}/contractor-assignments/${contractorAssignmentId}/periods`;
export const deletePeriodUrl = (
  tenantName: string,
  contractorAssignmentId: string,
  periodId: string
) => `
    ${baseOnAccountUrl}/${tenantName}/contractor-assignments/${contractorAssignmentId}/periods/${periodId}
 `;

 export const contractorPeriodSumUrl = (
    tenantName: string,
    projectId: string,
    startDate: string,
    endDate: string
  ) => `${baseOnAccountUrl}/${tenantName}/projects/${projectId}/from/${startDate}/to/${endDate}`;