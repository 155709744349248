import React from 'react';

import { Button } from 'react-bootstrap';

interface Props {
  buttonAction: string;
  handleOnClick: () => void;
  title: string;
}

function Subheader(props: Props) {
  const { buttonAction, handleOnClick, title } = props;

  return (
    <div className="subheader">
      <div className="subheader__title">
        <h2>{title}</h2>
        <Button variant="success" onClick={handleOnClick}>
          {buttonAction}
        </Button>
      </div>
    </div>
  );
}

export default Subheader;
