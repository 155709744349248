import { ITaskGroup, ITask, ITaskSubGroup, IMainTaskGroup } from "../types";

export function fillOutTaskGroups(taskGroups: ITaskGroup[], tasks: ITask[]): IMainTaskGroup[] {
    const taskSubGroups: ITaskSubGroup[] = [];
    const mainTaskGroups: IMainTaskGroup[] = [];
    let lastMainTaskGroupIndex = 0;
    
    taskGroups.forEach((taskGroup) => {
      if (!taskGroup.taskGroupId) {
        lastMainTaskGroupIndex =
          mainTaskGroups.push({
            ...taskGroup,
            taskGroups: [],
            tasks: [],
            value: 0,
          }) - 1;
      } else {
        const index =
          taskSubGroups.push({
            ...taskGroup,
            tasks: [],
            value: 0,
          }) - 1;
        tasks.forEach((task) => {
          if (taskGroup.id === task.taskGroupId) {
            taskSubGroups[index].tasks.push(task);
            taskSubGroups[index].value += task.value;
          }
        });
        mainTaskGroups[lastMainTaskGroupIndex].taskGroups.push(
          taskSubGroups[index]
        );
        mainTaskGroups[lastMainTaskGroupIndex].value +=
          taskSubGroups[index].value;
      }
    });

    mainTaskGroups.forEach((mainTaskGroup) => {
      if (!mainTaskGroup.taskGroups.length) {
        tasks.forEach((task) => {
          if (task.taskGroupId === mainTaskGroup.id) {
            mainTaskGroup.tasks.push(task);
            mainTaskGroup.value += task.value;
          }
        });
      }
    });

    return mainTaskGroups;
}