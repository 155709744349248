import { useCallback, useContext } from 'react';

import { useApi } from '../api';

import { deleteProjectUrl } from '../../settings/api';

import { GlobalContext } from '../../contexts';
import { getStoredProject, history } from '../../utils';

export function useProjectsDelete() {
  const {
    tenant: { name: tenantName },
    setLoading,
  } = useContext(GlobalContext);

  const { apiDelete } = useApi();

  const { GUID: projectGUID } = getStoredProject();

  const deleteProject = useCallback(async () => {
    try {
      setLoading(true);
      await apiDelete(deleteProjectUrl(tenantName, projectGUID));

      setLoading(false);

      setTimeout(() => {
        history.replace('/projects');
      }, 700);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }, [apiDelete, projectGUID, setLoading, tenantName]);

  return { deleteProject };
}
