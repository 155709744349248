import React, { Fragment, useState } from 'react';

import SkeletonTable from '../Skeleton/SkeletonTable';
import DomainListItem from './DomainListItem';

import { IDomain } from '../../types';

interface Props {
  domains: IDomain[];
  displayMessage: string;
  isSuperAdmin: boolean;
  editDomainClick: (domain: IDomain) => void;
  deleteDomainClick: (domain: IDomain) => void;
}

function DomainsList({
  domains,
  displayMessage,
  isSuperAdmin,
  editDomainClick,
  deleteDomainClick,
}: Props) {
  const [openedContextMenu, setOpenedContextMenu] = useState('');

  const changeOpenedContextMenu = (id: string) => {
    openedContextMenu === id
      ? setOpenedContextMenu('')
      : setOpenedContextMenu(id);
  };

  const classNamePrefix = isSuperAdmin ? 'super-admin-' : '';
  return (
    <>
      {!displayMessage && domains.length ? (
        <div className={`${classNamePrefix}domains-content__list`}>
          <div />
          <div
            className={`${classNamePrefix}domains-content__list__logo header`}
          >
            Logo
          </div>
          <div className={`${classNamePrefix}domains-content__list__id header`}>
            ID
          </div>
          <div
            className={`${classNamePrefix}domains-content__list__tenantName header`}
          >
            Tenant
          </div>
          <div
            className={`${classNamePrefix}domains-content__list__screen-image header`}
          >
            Screen image
          </div>
          <div
            className={`${classNamePrefix}domains-content__list__dark-color header`}
          >
            Dark color
          </div>
          <div
            className={`${classNamePrefix}domains-content__list__light-color header`}
          >
            Light color
          </div>
          <div />
          <div />
          {domains.map((domain) => (
            <Fragment key={domain.id}>
              <DomainListItem
                domain={domain}
                openedContextMenu={openedContextMenu}
                classNamePrefix={classNamePrefix}
                changeOpenedContextMenu={changeOpenedContextMenu}
                handleEditClick={editDomainClick}
                handleDeleteClick={deleteDomainClick}
              />
            </Fragment>
          ))}
        </div>
      ) : displayMessage ? (
        <div className="contractors-display-message">
          <span>{displayMessage}</span>
        </div>
      ) : (
        <SkeletonTable />
      )}
    </>
  );
}

export default DomainsList;
