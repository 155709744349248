import React, { useContext, useState, useRef } from 'react';

import { GlobalContext } from '../../contexts';

import { useHover } from '../../hooks/eventHandler';

import { history } from '../../utils';

interface Props {
  title: string;
  path: string;
  children: React.ReactNode;
}

function PopupMenuItem(props: Props) {
  const [isItemHovered, setItemHover] = useState(false);
  const { tenant } = useContext(GlobalContext);
  const { children, path, title } = props;
  const itemRef = useRef(null);

  const itemStyle = {
    backgroundColor: isItemHovered ? tenant.lightColor : 'white',
  };

  const handleItemClick = () => {
    history.push(path);
  };

  const handleItemHover = () => {
    setItemHover(!isItemHovered);
  };

  useHover(itemRef, handleItemHover);

  return (
    <div
      className="popup-menu__item"
      style={itemStyle}
      ref={itemRef}
      onClick={handleItemClick}
    >
      {children}
      <span>{title}</span>
    </div>
  );
}

export default PopupMenuItem;
