import React, { useState, useRef } from 'react';

import TableItemMore from '../Items/TableItemMore';
import TableContextMenu from '../Menu/TableContextMenu';

import { useHover, useOutsideEvent } from '../../hooks/eventHandler';

import {
  IContractor,
  IContractorAssignment,
  IPeriodSumPerContractor,
} from '../../types';

import {
  formatNumber,
  getStoredProject,
  setStoredContractor,
  history,
} from '../../utils';

interface Props {
  contractor: IContractor;
  contractorAssignment?: IContractorAssignment;
  periodSumPerContractor: IPeriodSumPerContractor;
  hoverColor: string;
  openedContractorMenu: string;
  setOpenedContractorMenu: (id: string) => void;
  handleMenuEditClick: (contractor: IContractor, trade?: string) => void;
  handleMenuDeleteClick: (contractor: IContractor) => void;
}

function ContractorsTableRow(props: Props) {
  const [isRowHovered, setRowHover] = useState(false);
  const {
    contractor,
    contractorAssignment,
    periodSumPerContractor,
    hoverColor,
    openedContractorMenu,
    setOpenedContractorMenu,
    handleMenuEditClick,
    handleMenuDeleteClick,
  } = props;
  const idRef = useRef(null);
  const tradeRef = useRef(null);
  const nameRef = useRef(null);
  const sumRef = useRef(null);
  const contactRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const menuRef = useRef(null);
  const { GUID: projectGUID } = getStoredProject();
  const showMenu = openedContractorMenu === contractor.id;

  const tableRowStyle = isRowHovered
    ? {
        borderLeft: `7px solid ${hoverColor}`,
      }
    : {};
  const folderIconStyle = {
    color: isRowHovered ? hoverColor : '#94abc8',
  };

  const handleElementHover = (hasHover: boolean) => {
    setRowHover(hasHover);
  };

  const handleClickMore = () => {
    setOpenedContractorMenu(contractor.id);
  };

  const handleEditClick = () => {
    handleMenuEditClick(contractor, contractorAssignment?.trade);
  };

  const handleDeleteClick = () => {
    handleMenuDeleteClick(contractor);
  };

  const handleContractorClick = () => {
    setStoredContractor(contractor);
    history.push(
      `/projects/${projectGUID}/contractor-assignments/${contractorAssignment?.id}`
    );
  };

  const handleMenuOutsideClick = () => {
    if (showMenu) setOpenedContractorMenu('');
  };

  const contractorSum = periodSumPerContractor[contractorAssignment?.id || 0];

  useHover(idRef, handleElementHover);
  useHover(tradeRef, handleElementHover);
  useHover(nameRef, handleElementHover);
  useHover(sumRef, handleElementHover);
  useHover(contactRef, handleElementHover);
  useHover(emailRef, handleElementHover);
  useHover(phoneRef, handleElementHover);
  useHover(menuRef, handleElementHover);
  useOutsideEvent(menuRef, handleMenuOutsideClick);

  return (
    <>
      <div
        className="contractors-table__id data-row bordered"
        onClick={handleContractorClick}
        ref={idRef}
        style={tableRowStyle}
      >
        <i
          className="material-icons contractors-table__folder-icon"
          style={folderIconStyle}
        >
          folder_shared
        </i>
        <span>{contractor.displayId}</span>
      </div>
      <div
        className="contractors-table__trade data-row"
        onClick={handleContractorClick}
        ref={tradeRef}
      >
        {contractorAssignment?.trade || ' - '}
      </div>
      <div
        className="contractors-table__name data-row"
        onClick={handleContractorClick}
        ref={nameRef}
      >
        {contractor.displayName}
      </div>
      <div className="contractors-table__sum data-row" ref={sumRef}>
        {contractorSum ? formatNumber(contractorSum) : 0}
      </div>
      <div className="contractors-table__contact data-row" ref={contactRef}>
        {contractor.contactPersonName || '-'}
      </div>
      <div className="contractors-table__email data-row" ref={emailRef}>
        {contractor.contactPersonEmail || '-'}
      </div>
      <div className="contractors-table__phone data-row" ref={phoneRef}>
        {contractor.contactPersonPhone || '-'}
      </div>
      <TableItemMore
        clickHandler={handleClickMore}
        isMenuOpened={showMenu}
        handleHover={handleElementHover}
      />
      <TableContextMenu
        show={showMenu}
        ref={menuRef}
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
        handleHover={handleElementHover}
      />
    </>
  );
}

export default ContractorsTableRow;
