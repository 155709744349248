import React, { CSSProperties, useContext, useRef, useState } from 'react';

import { GlobalContext } from '../../contexts';

import { useHover } from '../../hooks/eventHandler';

import { history } from '../../utils';

interface Props {
  title: string;
  iconName: string;
  isSuperAdmin: boolean;
  css?: CSSProperties;
}

function AdminListItem({ title, iconName, isSuperAdmin, css }: Props) {
  const [isListItemHovered, setListItemHover] = useState(false);
  const {
    tenant: { lightColor },
  } = useContext(GlobalContext);
  const listItemRef = useRef<HTMLDivElement>(null);

  const listItemStyle = {
    backgroundColor: isListItemHovered ? lightColor : 'white',
  };

  const handleListItemHover = () => {
    setListItemHover(!isListItemHovered);
  };

  const handleListItemClick = () => {
    isSuperAdmin
      ? history.push(`/superadmin/${title.toLowerCase()}`)
      : history.push(`/admin/${title.toLowerCase()}`);
  };

  useHover(listItemRef, handleListItemHover);

  return (
    <div
      className="admin-sidebar__list-item"
      style={{ ...listItemStyle, ...css }}
      ref={listItemRef}
      onClick={handleListItemClick}
    >
      <i className="material-icons">{iconName}</i>
      <span>{title}</span>
    </div>
  );
}

export default AdminListItem;
