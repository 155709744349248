import React from 'react';

import AdminSidebar from '../../components/Admin/AdminSidebar';

function Admin() {
  return (
    <div className="admin">
      <AdminSidebar />
      <div className="admin-content">
        <h1>Coming soon...</h1>
      </div>
    </div>
  );
}

export default Admin;
