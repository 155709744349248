import React, { useContext, useState, useRef } from 'react';
import { Button } from 'react-bootstrap';

import { GlobalContext } from '../../contexts';

import { useHover } from '../../hooks/eventHandler';

import { setStoredProject, history } from '../../utils';

import { ReactComponent as FolderLightIcon } from '../../assets/folder-light.svg';
import { ReactComponent as FolderDarkIcon } from '../../assets/folder-dark.svg';

interface Props {
  id: string;
  projectId: string;
  projectDisplayName: string;
}

function ProjectCard(props: Props) {
  const [isCardHovered, setCardHover] = useState(false);
  const { id, projectId, projectDisplayName } = props;
  const { tenant } = useContext(GlobalContext);
  const projectCardRef = useRef(null);

  const handleCardClick = () => {
    setStoredProject({ projectId, projectDisplayName, GUID: id });
    history.push(`/projects/${id}`);
  };

  const handleCardHover = (hasHover: boolean) => {
    setCardHover(hasHover);
  };

  const projectCardBackground = {
    backgroundColor: isCardHovered ? tenant.darkColor : tenant.lightColor,
  };

  const buttonBackground = isCardHovered
    ? {
        backgroundColor: '#fff',
        color: tenant.darkColor,
      }
    : {
        backgroundColor: tenant.darkColor,
        color: '#fff',
      };

  useHover(projectCardRef, handleCardHover);
  return (
    <div
      className="project-card"
      style={projectCardBackground}
      ref={projectCardRef}
      onClick={handleCardClick}
    >
      <h6 className="project-card__project-id">{projectId}</h6>
      <h6 className="project-card__display-name">{projectDisplayName}</h6>
      <Button size="lg" className="project-card__btn" style={buttonBackground}>
        View
      </Button>
      <div className="project-card__blocks">
        <FolderLightIcon className="project-card__blocks__light-icon" />
        <FolderDarkIcon className="project-card__blocks__dark-icon" />
      </div>
    </div>
  );
}

export default ProjectCard;
