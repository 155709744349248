import { useContext, useEffect, useState } from 'react';

import { useApi } from '../api';

import { tenantUsersUrl } from '../../settings/api';

import { GlobalContext } from '../../contexts';

import { ITenantUser } from '../../types';

export function useTenantUsersGet() {
  const [users, setUsers] = useState<ITenantUser[]>([]);
  const [displayMessage, setDisplayMessage] = useState('');
  const {
    tenant: { name: tenantName },
    setLoading,
  } = useContext(GlobalContext);

  const { apiGet } = useApi();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response: ITenantUser[] = await apiGet(tenantUsersUrl(tenantName));
      setUsers(response);
      if (!response.length) {
        setDisplayMessage('There is no any user registered for this tenant.');
      }
      setLoading(false);
    })();
  }, [apiGet, tenantName, setLoading]);

  return {
    users,
    setUsers,
    displayMessage,
    setDisplayMessage,
  };
}
