import React, { useRef, useState } from 'react';

import BorderIcon from '../TableCommon/BorderIcon';

import { useHover } from '../../hooks';

interface Props {
  name: string;
}

function TenantListItem({ name }: Props) {
  const [hasHover, setHover] = useState(false);
  const borderIconRef = useRef(null);
  const nameRef = useRef(null);

  const handleElementHover = (hasHover: boolean) => {
    setHover(hasHover);
  };

  useHover(borderIconRef, handleElementHover);
  useHover(nameRef, handleElementHover);

  return (
    <>
      <BorderIcon isHovered={hasHover} iconName="home" ref={borderIconRef} />
      <div className="super-admin-content__list__name data-row" ref={nameRef}>
        {name}
      </div>
    </>
  );
}

export default TenantListItem;
