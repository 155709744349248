import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useApi } from '../api';

import { taskGroupUrl } from '../../settings/api';

import { GlobalContext } from '../../contexts';

import { ITaskGroup } from '../../types';

export function useTaskGroupsGet() {
  const [taskGroups, setTaskGroups] = useState<ITaskGroup[]>([]);
  const [displayMessage, setDisplayMessage] = useState('');
  const {
    tenant: { name: tenantName },
    setLoading,
  } = useContext(GlobalContext);
  const {
    contractorAssignmentId,
  }: { contractorAssignmentId: string } = useParams();

  const { apiGet } = useApi();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response: ITaskGroup[] = await apiGet(
        taskGroupUrl(tenantName, contractorAssignmentId)
      );
      setTaskGroups(response);
      if (!response.length) {
        setDisplayMessage(
          'There are not yet any task group for this contractor.'
        );
      }
      setLoading(false);
    })();
  }, [apiGet, contractorAssignmentId, setLoading, tenantName, setTaskGroups]);

  return {
    taskGroups,
    setTaskGroups,
    displayMessage,
    setDisplayMessage,
  };
}
