import { useCallback, useContext, useState } from 'react';

import { GlobalContext } from '../../contexts';

import { useApi } from '../api';

import { tenantsUrl } from '../../settings/api';

export function useTenantPost() {
  const { setLoading } = useContext(GlobalContext);
  const { apiPost } = useApi();
  const [isSuccess, setSuccess] = useState(false);

  const createTenant = useCallback(
    async (name: string) => {
      try {
        setLoading(true);
        const { id: newTenant } = await apiPost(tenantsUrl(), { name });

        setLoading(false);
        setSuccess(true);

        return newTenant;
      } catch (error) {
        setLoading(false);
        setSuccess(false);
      }
    },
    [apiPost, setLoading]
  );

  return {
    createTenant,
    isSuccess,
    setSuccess,
  };
}
