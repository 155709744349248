import { PROJECT, CONTRACTOR, PERIOD } from '../constants/storage';

interface IStoredProject {
  GUID: string;
  projectDisplayName: string;
  projectId: string;
}

interface IStoredContractor {
  id: string;
  displayId: string;
  displayName: string;
  contactPersonName?: string;
  contactPersonEmail?: string;
  contactPersonPhone?: string;
}

interface IStoredPeriod {
  id: string;
  startDate: string;
  sum: number;
}

function setStoredProject({
  GUID,
  projectDisplayName,
  projectId,
}: IStoredProject) {
  localStorage.setItem(
    PROJECT,
    JSON.stringify({ GUID, projectDisplayName, projectId })
  );
}

function setStoredContractor({
  id,
  displayId,
  displayName,
  contactPersonName,
  contactPersonEmail,
  contactPersonPhone,
}: IStoredContractor) {
  localStorage.setItem(
    CONTRACTOR,
    JSON.stringify({
      id,
      displayId,
      displayName,
      contactPersonName,
      contactPersonEmail,
      contactPersonPhone,
    })
  );
}

function setStoredPeriod({ id, startDate, sum }: IStoredPeriod) {
  localStorage.setItem(PERIOD, JSON.stringify({ id, startDate, sum }));
}

function getStoredProject() {
  const storedProject = localStorage.getItem(PROJECT);
  return storedProject ? JSON.parse(storedProject) : {};
}

function getStoredContractor() {
  const storedContractor = localStorage.getItem(CONTRACTOR);
  return storedContractor ? JSON.parse(storedContractor) : {};
}

function getStoredPeriod() {
  const storedPeriod = localStorage.getItem(PERIOD);
  return storedPeriod ? JSON.parse(storedPeriod) : {};
}

function removeStoredItems() {
  localStorage.removeItem(PROJECT);
  localStorage.removeItem(CONTRACTOR);
  localStorage.removeItem(PERIOD);
}

export {
  setStoredProject,
  setStoredContractor,
  setStoredPeriod,
  getStoredProject,
  getStoredContractor,
  getStoredPeriod,
  removeStoredItems,
};
