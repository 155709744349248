import React, { useState } from 'react';
import { ENTER_CODE, ESCAPE_CODE } from '../../constants/keyCodes';
import { IWork, ICompletedPercentages, ICustomWork } from '../../types';
import { formatNumber } from '../../utils';

interface Props {
  customWork: ICustomWork;
  completedPercentages: ICompletedPercentages;
  createNewWork: (taskId: string, percentageOfTask: number) => void;
  updateWork: (work: IWork) => void;
  updateTotalValue: (newValue: number, oldValue: number) => void;
}

function CalculationsTableRow(props: Props) {
  const {
    customWork: { task, work, lastMonthWork },
    completedPercentages,
    createNewWork,
    updateWork,
    updateTotalValue,
  } = props;
  const [completedThisPeriod, setCompletedThisPeriod] = useState(
    work ? `${work.percentageOfTask}%` : '0%'
  );
  const [
    previousCompletedThisPeriod,
    setPreviousCompletedThisPeriod,
  ] = useState(work ? `${work.percentageOfTask}%` : '0%');

  const handlePeriodCompletionChange = (e: any) => {
    const parsedCurrentInput = isNaN(parseFloat(e.target.value))
      ? 0
      : parseFloat(e.target.value);
    const parsedCompletedThisPeriod = isNaN(parseFloat(completedThisPeriod))
      ? 0
      : parseFloat(completedThisPeriod);

    const newPeriodCompleteValue = (task.value * parsedCurrentInput) / 100;
    const oldPeriodCompleteValue =
      (task.value * parsedCompletedThisPeriod) / 100;

    updateTotalValue(newPeriodCompleteValue, oldPeriodCompleteValue);
    setCompletedThisPeriod(e.target.value);
  };

  const handlePeriodCompletionKeyDown = (e: any) => {
    if (e.keyCode === ENTER_CODE) {
      const newValue = parseFloat(e.target.value);
      if (!isNaN(newValue) && newValue <= 100 && newValue >= 0) {
        setCompletedThisPeriod(`${newValue}%`);
        setPreviousCompletedThisPeriod(`${newValue}%`);
      }
      return e.target.blur();
    } else if (e.keyCode === ESCAPE_CODE) {
      const parsedCompletedThisPeriod = isNaN(parseFloat(completedThisPeriod))
        ? 0
        : parseFloat(completedThisPeriod);
      const newPeriodCompleteValue =
        (task.value * parseFloat(previousCompletedThisPeriod)) / 100;
      const oldPeriodCompleteValue =
        (task.value * parsedCompletedThisPeriod) / 100;
      updateTotalValue(newPeriodCompleteValue, oldPeriodCompleteValue);
      setCompletedThisPeriod(previousCompletedThisPeriod);
      e.target.value = previousCompletedThisPeriod;
      return e.target.blur();
    }
  };

  const handlePeriodCompletionOnBlur = (e: any) => {
    const value = parseFloat(e.target.value);

    if (!work && value) {
      createNewWork(task.id, value);
    }

    if (isNaN(value) || value > 100 || value < 0) {
      const parsedCompletedThisPeriod = isNaN(parseFloat(completedThisPeriod))
        ? 0
        : parseFloat(completedThisPeriod);
      const newPeriodCompleteValue =
        (task.value * parseFloat(previousCompletedThisPeriod)) / 100;
      const oldPeriodCompleteValue =
        (task.value * parsedCompletedThisPeriod) / 100;
      updateTotalValue(newPeriodCompleteValue, oldPeriodCompleteValue);
      return setCompletedThisPeriod(previousCompletedThisPeriod);
    }

    if (value === parseFloat(`${previousCompletedThisPeriod}`)) {
      return;
    }

    if (work) {
      work.percentageOfTask = value;
      updateWork(work);
    }

    setCompletedThisPeriod(`${value}%`);
    setPreviousCompletedThisPeriod(`${value}%`);
  };

  const parsedCompletedThisPeriod = isNaN(parseFloat(completedThisPeriod))
    ? 0
    : parseFloat(completedThisPeriod);
  const currentMonthAmount = (task.value * parsedCompletedThisPeriod) / 100;
  const lastMonthAmount =
    (task.value * (lastMonthWork ? lastMonthWork.percentageOfTask : 0)) / 100;
  const completedInTotal =
    Object.keys(completedPercentages).length && completedPercentages[task.id]
      ? `${completedPercentages[task.id]}%`
      : '0%';

  return (
    <>
      <div className="calculations-table__task data-row">
        <i className="material-icons">insert_chart</i>
        <div className="calculations-table__task__block">
          <span>{task.name}</span>
        </div>
      </div>
      <div className="calculations-table__completed-this-period data-row right-alignment">
        <input
          type="text"
          value={completedThisPeriod}
          onChange={handlePeriodCompletionChange}
          onKeyDown={handlePeriodCompletionKeyDown}
          onBlur={handlePeriodCompletionOnBlur}
        />
      </div>
      <div className="calculations-table__completed-total data-row right-alignment">
        {completedInTotal}
      </div>
      <div className="calculations-table__last-month-amount data-row right-alignment">
        <span>{formatNumber(lastMonthAmount)}</span>
      </div>
      <div className="calculations-table__current-month-amount data-row right-alignment">
        <span>{formatNumber(currentMonthAmount)}</span>
      </div>
      <div className="calculations-table__total-contract-amount data-row right-alignment">
        <span>{formatNumber(task.value)}</span>
      </div>
    </>
  );
}

export default CalculationsTableRow;
