import React, { useContext, useRef, useState } from 'react';

import Logo from '../Logo/Logo';
import PopupMenu from '../Menu/PopupMenu';
import PopupMenuItem from '../Items/PopupMenuItem';
import { UserProfileItem } from '../Items';

import { GlobalContext } from '../../contexts';

import { useOutsideEvent } from '../../hooks/eventHandler';

import { history } from '../../utils';

import { ReactComponent as HamburgerMenu } from '../../assets/hamburger-menu.svg';
import { ReactComponent as LogoutIcon } from '../../assets/logout.svg';

function Sidebar() {
  const [isPopupMenuVisible, setPopupMenuVisibility] = useState(false);
  const { tenant } = useContext(GlobalContext);
  const menuRef = useRef(null);

  const handleSettingsClick = () => {
    history.push('/admin');
  };

  const handleMenuOutsideEvent = () => {
    setPopupMenuVisibility(false);
  };

  useOutsideEvent(menuRef, handleMenuOutsideEvent);

  return (
    <div className="sidebar">
      <HamburgerMenu className="sidebar__hamburger-menu" />
      <Logo logoUrl={tenant.logoUrl} />
      <div className="sidebar__footer">
        <div className="sidebar__footer__settings">
          <i className="material-icons" onClick={handleSettingsClick}>
            settings
          </i>
        </div>
        <UserProfileItem />
      </div>
      <PopupMenu show={isPopupMenuVisible} ref={menuRef}>
        <PopupMenuItem title="Logout" path="/logout">
          <LogoutIcon className="popup-menu__item__icon" />
        </PopupMenuItem>
      </PopupMenu>
    </div>
  );
}

export default Sidebar;
