import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useApi } from '../api';

import { taskUrl } from '../../settings/api';

import { GlobalContext } from '../../contexts';

import { ITask } from '../../types';

export function useTasksGet() {
  const [tasks, setTasks] = useState<ITask[]>([]);
  const {
    tenant: { name: tenantName },
    setLoading,
  } = useContext(GlobalContext);
  const {
    contractorAssignmentId,
  }: { contractorAssignmentId: string } = useParams();

  const { apiGet } = useApi();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response: ITask[] = await apiGet(
        taskUrl(tenantName, contractorAssignmentId)
      );
      setTasks(response);
      setLoading(false);
    })();
  }, [apiGet, tenantName, contractorAssignmentId, setLoading, setTasks]);

  return {
    tasks,
    setTasks,
  };
}
