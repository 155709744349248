import React from 'react';
import { Button } from 'react-bootstrap';
import SkeletonLine from '../Skeleton/SkeletonLine';

interface Props {
  mainButtonAction: string;
  additionalButtonAction?: string;
  title?: string;
  handleMainButtonOnClick: () => void;
  handleAdditionalButtonOnClick?: () => void;
}

function SubheaderActionRow(props: Props) {
  const {
    mainButtonAction,
    additionalButtonAction,
    handleMainButtonOnClick,
    handleAdditionalButtonOnClick,
    title,
  } = props;

  return (
    <div className="subheader-action-row">
      <div className="subheader-action-row__title">
        <h4>{title ? title : <SkeletonLine />}</h4>
        {title ? (
          <div className="subheader-action-row__buttons">
            {additionalButtonAction ? (
              <Button
                variant="primary"
                onClick={handleAdditionalButtonOnClick}
                className="subheader-action-row__buttons__additional"
              >
                {additionalButtonAction}
              </Button>
            ) : (
              ''
            )}
            <Button
              variant="success"
              onClick={handleMainButtonOnClick}
              className="subheader-action-row__buttons__main"
            >
              {mainButtonAction}
            </Button>
          </div>
        ) : (
          <SkeletonLine />
        )}
      </div>
    </div>
  );
}

export default SubheaderActionRow;
